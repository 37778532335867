export const getTextErrors = (): Record<string, string> => {
  return {
    USER_NOT_FOUND: 'Номер телефону ще не зареєстрований. Зверніться до кадрового фахівця вашого підприємства',
    CODE_VERIFICATION_FAILED: 'Невірний код доступу, спробуйте ще раз',
    USER_BLOCKED: 'Ваш обліковий запис заблоковано на 5 хвилин.',
    SERVER_ERROR: 'Помилка сервера, звернітся до системного адміністратора',
    USER_FORBIDDEN: 'Прохання оформити відпустку за допомогою K2'
  };
};

export const textError = getTextErrors();
