import { createSlice } from '@reduxjs/toolkit';
import {
  confirmCode,
  getPartTimeJobProfiles,
  requestCode
} from './sessions.actions';

import { SessionView, type InitialStateProps } from './types';

const initialState: InitialStateProps = {
  error: null,
  userPhone: '',
  userPib: '',
  userBlockedTimestamp: null,
  sessionView: SessionView.login,
  isLoadingRequestCode: false,
  isLoadingConfirmCode: false,
  profile: null,
  partTimeJobProfiles: {
    items: [],
    isLoading: false
  }
};

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setSessionErrors: (state, action) => {
      state.error = action.payload;
    },
    setSessionView: (state, action) => {
      state.sessionView = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userPhone = action.payload.userPhone;
      state.userPib = action.payload.userPib;
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setUserBlockedTimestampErrors: (state, action) => {
      state.userBlockedTimestamp = action.payload;
    },
    clearSessionsError: (state) => {
      state.error = null;
    },
    clearSessionsState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(requestCode.pending, (state) => {
      state.isLoadingRequestCode = true;
    });
    builder.addCase(requestCode.fulfilled, (state) => {
      state.isLoadingRequestCode = false;
    });
    builder.addCase(requestCode.rejected, (state, action) => {
      state.isLoadingRequestCode = false;
    });

    builder.addCase(confirmCode.pending, (state) => {
      state.isLoadingConfirmCode = true;
    });
    builder.addCase(confirmCode.fulfilled, (state) => {
      state.isLoadingConfirmCode = false;
    });
    builder.addCase(confirmCode.rejected, (state) => {
      state.isLoadingConfirmCode = false;
    });

    builder.addCase(getPartTimeJobProfiles.pending, (state) => {
      state.partTimeJobProfiles.isLoading = true;
    });
    builder.addCase(getPartTimeJobProfiles.fulfilled, (state, action) => {
      state.partTimeJobProfiles.isLoading = false;
      state.partTimeJobProfiles.items = action.payload;
    });
    builder.addCase(getPartTimeJobProfiles.rejected, (state) => {
      state.partTimeJobProfiles.isLoading = false;
    });
  }
});

export const {
  setSessionErrors,
  clearSessionsState,
  setSessionView,
  clearSessionsError,
  setUserInfo,
  setUserProfile,
  setUserBlockedTimestampErrors
} = sessionsSlice.actions;

export default sessionsSlice.reducer;
