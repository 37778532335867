export const ERROR_TYPES = {
  REQUIRED: 'REQUIRED',
  REQUIRED_FILE: 'REQUIRED_FILE',
  NO_AVAILABLE_DAYS: 'NO_AVAILABLE_DAYS'
};

export const ERROR_TEXTS = {
  [ERROR_TYPES.REQUIRED]: 'Обов`язкове поле',
  [ERROR_TYPES.REQUIRED_FILE]: 'Для оформлення відпустки додайте документи',
  [ERROR_TYPES.NO_AVAILABLE_DAYS]: 'Дні відпустки вичерпано'
};
