import React, { useCallback, type ReactNode } from 'react';
import styled from 'styled-components';
import { v1 as uuidv1 } from 'uuid';

import { Flex, Grid, Pagination, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { type ILeaveApplicationPagination } from 'src/types/leaveApplications/types';
import { ReactComponent as EmptyStateIcon } from 'src/assets/images/empty-state.svg';

const { useBreakpoint } = Grid;

const StackItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 14px 0px;
  border-top: 1px solid ${(props) => props.theme.colors.borderColor};
`;

const StackRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
`;

const StackRowTitle = styled(Typography.Text)`
  font-size: 14px
  font-weight: 500;
  min-width: 100px;
  margin-right: 10px;
`;

const StackRowValue = styled(Typography.Text)<{ $isMobile: boolean }>`
  font-size: ${(props) => (props.$isMobile ? '14px' : '16px')};
  text-align: right;
`;

const NoDataMobile = styled.div<{ $isMobile: boolean }>`
  display: ${(props) => (props.$isMobile ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

interface IProps<TDataSource> {
  dataSource: TDataSource[];
  pagination?: ILeaveApplicationPagination | null;
  columns: ColumnsType<TDataSource>;
  isPagination?: boolean;
  onRow?: (e: React.MouseEvent<HTMLElement>, record: TDataSource) => void;
  onPaginationChange?: (page: number) => void
}

export const StackTable = <TDataSource,>({ dataSource, columns, isPagination, pagination, onRow, onPaginationChange }: IProps<TDataSource>) => {
  const screen = useBreakpoint();
  const isMobile = !!screen.xs;

  const handleOnClick = useCallback((row: TDataSource) => (e: React.MouseEvent<HTMLElement>) => {
    if (onRow) {
      onRow(e, row);
    }
  }, [onRow]);

  const rows = pagination ? dataSource.slice(
    (pagination?.currentPage - 1) * pagination?.pageSize,
    (pagination?.currentPage * pagination?.pageSize)
  ) : dataSource;

  if (!rows.length) {
    return (
      <NoDataMobile $isMobile>
        <Typography.Text disabled>Даних немає</Typography.Text>
        <EmptyStateIcon />
      </NoDataMobile>
    )
  }

  return (
    <>
      {rows.map((row, index) => (
        <StackItem key={uuidv1()}>
          {columns.map((col) => {
            const cellText = row[col.key as keyof TDataSource] as ReactNode;

            const cellValue = col.render
              ? (col.render(null, row, index) as ReactNode)
              : cellText;

            return (
              <StackRow key={col.key} onClick={handleOnClick(row)}>
                <StackRowTitle type="secondary">
                  {col.title?.toString()}
                </StackRowTitle>
                <StackRowValue $isMobile={isMobile}>{cellValue}</StackRowValue>
              </StackRow>
            );
          })}
        </StackItem>
      ))}
      {isPagination
        ? <Flex justify="flex-end">
            <Pagination
              showSizeChanger={false}
              defaultCurrent={1}
              total={pagination?.totalCount}
              pageSize={pagination?.pageSize}
              onChange={onPaginationChange}
            />
          </Flex>
        : null}
    </>
  );
};
