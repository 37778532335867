import moment, { type Moment } from 'moment';
import sanitizeHtml from 'sanitize-html';
import { useCallback, useEffect, useState } from 'react';
import { ERROR_TEXTS } from 'src/constants/errors/validation';
import { type IValidationError } from 'src/types/common';
import {
  type STEPPER_MODE,
  type ILeaveApplicationEntity,
  type ILeaveApplicationFormData,
  type ILeaveApplicationType,
  type IPartTimeJobEntityExtended,
  type IPreviewDocument
} from 'src/types/leaveApplications/types';
import { type IProfile } from 'src/types/user/types';
import { dateToTimeZone, getLeaveApplicationDateDiff } from 'src/utils/date';

interface IProps {
  prev?: ILeaveApplicationFormData;
  mode: STEPPER_MODE;
  profile: IProfile;
  partTimeJobs: { items: IPartTimeJobEntityExtended[] };
  leaveApplication?: ILeaveApplicationEntity;
  leaveApplicationDocuments?: IPreviewDocument[];
  leaveApplicationTypes?: ILeaveApplicationType[];
}

const getDate = (date?: string | Moment) => {
  if (!date) return dateToTimeZone(moment());
  if (moment.isMoment(date)) return date;

  return dateToTimeZone(moment(date));
}

const prepareInfo = (
  profile: IProfile,
  leaveApplication?: ILeaveApplicationEntity,
  leaveApplicationTypes?: ILeaveApplicationType[]
) => {
  // in edit/view mode, we need to set daysLimit according to leaveApplicationType
  const leaveApplicationType = leaveApplicationTypes?.find((type) => type.vacationIdentifier === leaveApplication?.vacationIdentifier);
  const daysLimit = leaveApplicationType?.daysLimit ?? 0;

  return {
    ...profile,
    principalName: profile.principalName,
    positionIdentifier: leaveApplication?.positionIdentifier ?? profile.positionIdentifier,
    position: leaveApplication?.positionName ?? profile.position,
    company: {
      label: leaveApplication?.companyName ?? profile.company,
      value: leaveApplication?.companyIdentifier ?? profile.companyIdentifier
    },
    department: {
      label: leaveApplication?.departmentName ?? profile.department,
      value: leaveApplication?.departmentName ?? profile.department
    },
    admManager: {
      label: leaveApplication?.admManagerPib ?? profile.admManagerPib,
      value: leaveApplication?.admManager ?? profile.admManager
    },
    fnManager: {
      label: leaveApplication?.fnManagerPib ?? profile.fnManagerPib,
      value: leaveApplication?.fnManager ?? profile.fnManager
    },
    vacationType: {
      label: leaveApplication?.vacationName ?? '',
      value: leaveApplication?.vacationIdentifier ?? ''
    },
    daysLimit,
    dateFrom: getDate(leaveApplication?.dateFrom),
    dateTo: getDate(leaveApplication?.dateTo),
    addToNextSalary: leaveApplication?.addToNextSalary ?? false,
    vacationId: leaveApplication?.vacationId,
    days: getLeaveApplicationDateDiff(getDate(leaveApplication?.dateFrom), getDate(leaveApplication?.dateTo)),
    comment: leaveApplication?.comment ? sanitizeHtml(leaveApplication?.comment) : '',
    statusId: leaveApplication?.statusId ?? 0
  }
}

const prepareDocuments = (leaveApplicationDocuments?: IPreviewDocument[]) => {
  return {
    fileList: leaveApplicationDocuments ?? []
  };
}

export const STEP_NUMBER_BY_NAME = {
  info: 0,
  documents: 1,
  partTimeJobs: 2
};

export const useStepperForm = ({
  mode,
  profile,
  leaveApplicationDocuments,
  partTimeJobs,
  leaveApplication,
  leaveApplicationTypes
}: IProps) => {
  const [formData, setStepperForm] = useState<ILeaveApplicationFormData>(
    {
      info: prepareInfo(profile, leaveApplication, leaveApplicationTypes),
      documents: prepareDocuments(leaveApplicationDocuments),
      partTimeJobs
    }
  );

  const [errors, setErrors] = useState<Record<number, IValidationError>>({
    [STEP_NUMBER_BY_NAME.info]: {},
    [STEP_NUMBER_BY_NAME.documents]: {},
    [STEP_NUMBER_BY_NAME.partTimeJobs]: {}
  });

  const changeStepErrors = (step: number, field: string, errorType: string) => {
    setErrors((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [field]: ERROR_TEXTS[errorType]
      }
    }));
  };

  const resetStepErrors = (step: number) => {
    setErrors((prev) => ({
      ...prev,
      [step]: {}
    }));
  };

  const changeFormData = useCallback(
    (step: keyof ILeaveApplicationFormData) => (name: string, value: unknown) => {
      changeStepErrors(STEP_NUMBER_BY_NAME[step], name, ''); // clean up field errors

      setStepperForm((prev) => ({
        ...prev,
        [step]: {
          ...prev[step],
          [name]: value
        }
      }));
    },
    []
  );

  useEffect(() => {
    if (leaveApplication && profile) {
      setStepperForm((prev) => ({
        ...prev,
        info: prepareInfo(profile, leaveApplication, leaveApplicationTypes)
      }));
    }
  }, [mode, profile, leaveApplication, leaveApplicationTypes]);

  useEffect(() => {
    if (partTimeJobs) {
      setStepperForm((prev) => ({
        ...prev,
        partTimeJobs: {
          items: partTimeJobs.items.map((item, i) => ({
            ...prev.partTimeJobs.items[i],
            ...item,
            isActive: prev.partTimeJobs.items[i] ? prev.partTimeJobs.items[i].isActive : item.isActive,
            disabled: prev.partTimeJobs.items[i] ? prev.partTimeJobs.items[i].disabled : item.disabled
          }))
        }
      }));
    }
  }, [partTimeJobs]);

  useEffect(() => {
    if (leaveApplicationDocuments) {
      setStepperForm((prev) => ({
        ...prev,
        documents: prepareDocuments(leaveApplicationDocuments)
      }));
    }
  }, [leaveApplicationDocuments]);

  return { formData, errors, changeStepErrors, resetStepErrors, changeFormData };
};
