import React, { useMemo } from 'react';

import { Select, Space, Typography } from 'antd';
import { type LeaveApplicationStatus, LeaveApplicationStatusData } from 'src/constants/status';
import { LeaveApplicationStatusColors } from 'src/features/theme/defaultTheme';
import styled from 'styled-components';

const StyledSelect = styled(Select)<{ $isActive: boolean }>`
  height: 40px;
  max-width: 200px;
  width: 100%;
  border-radius: 100px !important;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.borderColor};

  ${(props) =>
    props.$isActive && {
      backgroundColor: props.theme.colors.bgChecked,
      outlineColor: props.theme.colors.bgChecked
    }}

  .ant-select-selection-item {
    font-weight: 500;
    font-size: 16px;
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 16px;
  }

  .ant-select-selector, .ant-select-selector:hover {
    border: none !important;
  }

  .ant-select-clear {
    background: ${(props) => props.theme.colors.bgChecked};
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.borderHover};
  }
`;

interface IProps {
  statusFilter?: LeaveApplicationStatus | null;
  options: LeaveApplicationStatus[];
  onChange: (value: LeaveApplicationStatus) => void;
}

export const FilterSelect: React.FC<IProps> = ({ statusFilter, options, onChange }) => {
  const formattedOptions = useMemo(
    () =>
      options.map((status) => {
        const currentStatus = LeaveApplicationStatusData[status];
        const color = LeaveApplicationStatusColors[status].textColor;

        return {
          value: status,
          label: currentStatus.text,
          icon: currentStatus.icon,
          color
        };
      }),
    [options]
  );

  return (
    <StyledSelect
      // @ts-expect-error can not type additional props to styled components
      $isActive={(statusFilter && options.includes(statusFilter))}
      value={statusFilter && options.includes(statusFilter) ? statusFilter : undefined}
      onChange={onChange}
      options={formattedOptions}
      placeholder="Інше"
      bordered={false}
      allowClear
      optionRender={(option) => (
        <Space>
          {option.data.icon && (
            <option.data.icon style={{ fontSize: '24px', color: option.data.color }} />
          )}
          <Typography.Text style={{ fontWeight: 500 }}>{option.data.label}</Typography.Text>
        </Space>
      )}
    />
  );
};
