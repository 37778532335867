import { getTextErrors } from '../constants/errors/messages';

interface IFields {
  fields: {
    startAt: string;
    endAt: string;
  };
}

export const getErrorMessage = (type: string) => {
  const error = getTextErrors()[type] || type;

  return error;
};

export const getCorrectErrorWithFields = (error: IFields) => {
  const { fields } = error;

  return {
    ...error,
    fields: {
      ...fields,
      date:
        fields.startAt || fields.endAt
          ? {
              startAt: fields.startAt,
              endAt: fields.endAt
            }
          : undefined
    }
  };
};

export const handleRequest = async (promise: Promise<any>) => {
  try {
    const data = await promise;

    return data;
  } catch (err: any) {
    let error: any = {};

    if (err?.fields) {
      const { fields } = err;

      Object.keys(fields).forEach((key) => {
        const keySlug = key.split('/');

        let keyName: any = keySlug.at(-1);

        if (keySlug.at(0) === 'permissions') {
          keyName = keySlug.at(0);
        }

        if (keySlug.at(1) === 'translations') {
          keyName = keySlug.slice(1).join('/');
        }

        fields[keyName] = getErrorMessage(fields[key]);
      });

      error = getCorrectErrorWithFields(err);
    } else {
      error = err;
    }

    throw error;
  }
};
