import { Button, Input, Modal, Typography, Upload, type UploadFile } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { type TextAreaProps } from 'antd/es/input';
import { type UploadProps } from 'antd/lib';
import { CameraOutlined, Col, ErrorText, FileOutlined, Row, UploadOutlined } from 'src/components';
import { ACCEPTED_FILE_EXTENSIONS, ALLOWED_PREVIEW_TYPES } from 'src/constants/fileTypes';
import { useModal } from 'src/hooks/useModal';
import { ModalType } from 'src/store/modals/types';
import { type IValidationError } from 'src/types/common';
import { type ILeaveApplicationFormData } from 'src/types/leaveApplications/types';
import { getBase64Img } from 'src/utils/image/getBase64Src';
import { localStorage } from 'src/utils/storage';
import { usePreviewDocument } from '../../hooks/usePreviewDocument';
import { ORDER_NAME_SUFFIX } from 'src/constants/documentNames';
import { LeaveApplicationStatus } from 'src/constants/status';

const { Text } = Typography;
const { TextArea } = Input;

const Section = styled.section`
  padding: 0 28px;
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const StyledUpload = styled(Upload)`
  .ant-upload-select {
    width: 100%;
  }
`;

const IconRender = ({ file }: { file: UploadFile }) => {
  const [img, setImg] = useState<string | undefined>();

  useEffect(() => {
    const prepareImg = async () => {
      const base64Img = await getBase64Img(file, ALLOWED_PREVIEW_TYPES);

      setImg(base64Img);
    };

    prepareImg();
  }, [file]);

  if (!img) return <FileOutlined />;

  return <img src={img} />;
};

interface IProps {
  documents: ILeaveApplicationFormData['documents'];
  comment?: string;
  errors: IValidationError;
  onChange: (name: string, value: unknown) => void;
  onChangeComment: (name: string, value: unknown) => void;
  disabled?: boolean;
  leaveApplicationStatus?: LeaveApplicationStatus;
}

function filterOrdersDocumentsWithStamp (fileList: ILeaveApplicationFormData['documents']['fileList']) {
  return fileList.filter(doc => doc.document.name.includes(ORDER_NAME_SUFFIX));
}

export const UploadDocumentsForm: React.FC<IProps> = ({
  documents,
  comment,
  leaveApplicationStatus,
  errors,
  disabled,
  onChange,
  onChangeComment
}) => {
  const { previewOpen, previewImage, previewTitle, handlePreview, handleCancel } = usePreviewDocument();

  const fileList = leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder
    ? filterOrdersDocumentsWithStamp(documents.fileList)
    : documents.fileList;

  const isTerminal = localStorage.get('terminal');

  const [openModal] = useModal(ModalType.ErrorModal);
  const [openConfirmRemoveFileModal, closeConfirmRemoveFileModal] = useModal(
    ModalType.ConfirmRemoveFile
  );

  const handleFileChange: UploadProps['onChange'] = ({ file, fileList }) => {
    if (file.status !== 'removed' && !Object.values(ACCEPTED_FILE_EXTENSIONS).includes(file.type ?? '')) {
      openModal({
        title: 'Помилка',
        message: 'Формат файлу не підтримується'
      });

      return
    }

    if (documents && documents?.fileList.length > fileList.length) {
      if (!file.originFileObj) {
        openModal({
          title: 'Помилка',
          message: 'Неможливо видалити файл, який був завантажений при створенні заявки'
        });

        return
      }

      openConfirmRemoveFileModal({
        onSubmit: () => {
          onChange('fileList', fileList.map(item => ({ document: item })));
          closeConfirmRemoveFileModal();
        },
        onClose: closeConfirmRemoveFileModal
      });

      return;
    }

    onChange('fileList', fileList.map(item => ({ document: item })));
  };

  const handleChangeComment: TextAreaProps['onChange'] = (e) => {
    onChangeComment('comment', e.target.value);
  };

  const handleIconRender = useCallback((file: UploadFile) => {
    return <IconRender file={file} />;
  }, []);

  return (
    <Section>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} lg={8}>
          <UploadWrapper>
            <Text type="secondary">Документи</Text>
            <StyledUpload
              accept="image/*"
              onChange={handleFileChange}
              beforeUpload={() => false}
              showUploadList={false}
              fileList={fileList.map(item => item.document)}
              capture="user"
              disabled={disabled}
            >
              {!disabled &&
                <Button
                  block
                  size="large"
                  icon={<CameraOutlined style={{ fontSize: 20 }} />}
                  style={{ whiteSpace: 'normal', height: 'auto' }}>
                  Зробити фото документів
                </Button>
              }
            </StyledUpload>
            {isTerminal ? null : (
              <StyledUpload
                data-testid='uploadFile'
                accept={Object.values(ACCEPTED_FILE_EXTENSIONS).join(', ')}
                onChange={handleFileChange}
                beforeUpload={() => false}
                listType="picture"
                onPreview={handlePreview}
                iconRender={handleIconRender}
                fileList={fileList.map(item => item.document)}
                disabled={disabled}
              >
                {!disabled &&
                  <Button
                    block
                    size="large"
                    icon={<UploadOutlined style={{ fontSize: 20 }} />}
                    style={{ whiteSpace: 'normal', height: 'auto' }}
                  >
                    Завантажити фото документів
                  </Button>
                }
              </StyledUpload>
            )}
            <Modal data-testid='doc-preview' open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {errors?.fileList && <ErrorText>{errors.fileList}</ErrorText>}
          </UploadWrapper>
        </Col>
        <Col xs={24} sm={12} lg={16}>
          <Text type="secondary">Коментар (не обов’язкове)</Text>
          <TextArea
            placeholder="Введіть Ваш коментар"
            rows={5}
            value={comment}
            onChange={handleChangeComment}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Section>
  );
};
