import { type IPartTimeJobEntity, type ILeaveApplicationType } from 'src/types/leaveApplications/types';
import Base from './Base';
import { handleRequest } from 'src/utils/request';

export interface ILeaveApplicationTypesPayload {
  companyIdentifier: string;
}
export interface IPartTimeJobsPayload {
  vacationIdentifier: string;
  companyIdentifier: string;
  dateFrom: string;
  dateTo: string;
}

export type ILeaveApplicationTypesResponse = ILeaveApplicationType;

class LeaveApplicationTypes extends Base {
  getList = async (
    payload: ILeaveApplicationTypesPayload
  ): Promise<ILeaveApplicationTypesResponse[]> => {
    const { data } = await handleRequest(this.apiClient.get('vacation-types/list', payload));

    return data;
  };

  getAdditionalList = async (
    payload: IPartTimeJobsPayload
  ): Promise<IPartTimeJobEntity[]> => {
    const { data } = await handleRequest(
      this.apiClient.get('vacation-types/list/additional', payload)
    );

    return data;
  };
}

export default LeaveApplicationTypes;
