import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { type RootState } from 'src/store/rootStore';
import { type ModalType } from './types';

const getState = (state: RootState): RootState['modals'] => state.modals;

const activeModalsSelector = createSelector(getState, (state) => state.activeModals);

const isModalActiveSelector = (type: ModalType) =>
  createSelector(
    [(state: RootState) => state.modals.activeModals.find((modal) => modal.type === type)],
    (activeModal) => Boolean(activeModal)
  );

export function useActiveModals() {
  return useSelector(activeModalsSelector);
}

export function useModalActive(type: ModalType) {
  return useSelector(isModalActiveSelector(type));
}
