import React from 'react';
import { Modal, Typography } from 'antd';
import { type ConfirmCancelModalProps } from './ConfirmCancelModalProps';
import { ReactComponent as ExclamationIcon } from 'src/assets/images/exclamation.svg';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 24px;
  text-align: center;
`;

export const ConfirmCancelModal = ({ title, text, onClose, onSubmit }: ConfirmCancelModalProps) => {
  return (
    <Modal
      data-testid="CancelCreation"
      open
      title={title}
      onOk={onSubmit}
      onCancel={onClose}
      cancelText="Ні, продовжити"
      okText="Так, скасувати"
    >
      <Content>
        <ExclamationIcon />
        <Typography.Text style={{ fontSize: '16px' }}>
          {text}
        </Typography.Text>
      </Content>
    </Modal>
  );
};
