import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const HelpTextContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.helpTextColor};
  padding: 4px 8px;
  font-size: 12px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
`;

interface IProps {
  children: React.ReactNode;
}

export const HelpText: React.FC<IProps> = ({ children }) => {
  return (
    <HelpTextContainer>
      <StyledText>{children}</StyledText>
      </HelpTextContainer>
  );
};
