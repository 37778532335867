import {
  type IUpdateLeavePayload,
  type ILeaveApplicationFormData
} from 'src/types/leaveApplications/types';
import { formatDateWithoutTimezone } from '../date';

export const dumpUpdateLeaveApplication = (data: ILeaveApplicationFormData): IUpdateLeavePayload => {
  return {
    vacationId: data.info.vacationId as number,
    vacationIdentifier: data.info.vacationType.value as string,
    dateFrom: formatDateWithoutTimezone(data.info.dateFrom),
    dateTo: formatDateWithoutTimezone(data.info.dateTo),
    addToNextSalary: data.info.addToNextSalary,
    comment: data.info.comment ?? ''
  };
};
