import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import {
  Login,
  LeaveApplications,
  Main,
  CreateLeave,
  ViewLeave,
  EditLeave
} from 'src/features/pages';

import GlobalStyle from 'src/features/theme/globalStyles';
import { LoginLayout, AuthLayout, MainLayout, ModalLayout } from 'src/features/layout';
import { useBrowserStatus } from 'src/hooks/useBrowserStatus';
import { sessionStorage, localStorage } from 'src/utils/storage';
import { initializeNavigator } from 'src/utils/navigation';

interface IDummyLayout {
  children: React.ReactNode;
}

const dummyLayout: React.FC<IDummyLayout> = ({ children }) => {
  return <>{children}</>;
};

interface IAppRoute {
  component: React.FC;
  layout: React.FC<any>;
}

const PrivateRoute: React.FC<IAppRoute> = ({ component: Component, layout }) => {
  const token = sessionStorage.get('sessionToken');
  const Layout = layout ?? dummyLayout;

  if (!token) return <Navigate to={ROUTES.LOGIN} />;

  return (
    <Layout >
        <Component />
    </Layout>
  );
}

const AppRoute: React.FC<IAppRoute> = ({ component: Component, layout }) => {
  const Layout = layout ?? dummyLayout;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export const App: React.FC = () => {
  useBrowserStatus();
  const [queryParams] = useSearchParams();
  const isTerminal = queryParams.get('terminal');

  const navigate = useNavigate();

  initializeNavigator(navigate);

  useEffect(() => {
    if (isTerminal !== null) {
      localStorage.save('terminal', isTerminal === 'true');
    }
  }, [isTerminal]);

  return (
    <>
      <GlobalStyle />
      <ModalLayout />
      <MainLayout>
        <Routes>
          <Route path={ROUTES.ROOT} element={<PrivateRoute layout={AuthLayout} component={Main} />} />
          <Route path={ROUTES.LEAVE_APPLICATIONS} element={<PrivateRoute layout={AuthLayout} component={LeaveApplications} />} />
          <Route path={ROUTES.CREATE_LEAVE_APPLICATIONS} element={<PrivateRoute layout={AuthLayout} component={CreateLeave} />} />
          <Route path={ROUTES.VIEW_LEAVE_APPLICATIONS} element={<PrivateRoute layout={AuthLayout} component={ViewLeave} />} />
          <Route path={ROUTES.EDIT_LEAVE_APPLICATIONS} element={<PrivateRoute layout={AuthLayout} component={EditLeave} />} />
          <Route path={ROUTES.LOGIN} element={<AppRoute layout={LoginLayout} component={Login} />} />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
      </MainLayout>
    </>
  );
};
