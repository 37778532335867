export const MASKS_NAME = Object.freeze({
  PHONE: 'phone',
  CODE: 'code',
  EMAIL: 'email',
  CONFIRMATION: 'confirmation',
  DATE: 'date',
  DECIMAL: 'decimal',
  NUMBER: 'number',
  EDRPOU: 'edrpou',
  FLOAT: 'float'
});
