/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type {
  IApplicationDocumentData,
  ILeaveApplicationEntity,
  IValidateLeavePayload
} from './../../types/leaveApplications/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/app/apiSingleton';

import { NOTIFICATION_TYPE } from 'src/constants/notificationType';
import type {
  ISignApplicationDocument,
  ILeaveApplicationFormData,
  ICreateLeavePayload,
  IUpdateLeavePayload
} from 'src/types/leaveApplications/types';
import { dumpCreateLeaveApplication } from 'src/utils/dumps/dumpCreateLeaveApplication';
import { dumpValidateLeaveApplication } from 'src/utils/dumps/dumpValidateLeaveApplication';
import { showNotification } from 'src/utils/showNotification';
import { type RootState } from '../rootStore';
import { profileSelector } from '../sessions';
import {
  type IDumpSignedLeaveApplicationDocument,
  dumpSignedApplicationDocument
} from 'src/utils/dumps/dumpSignedApplicationDocument';
import { sleep } from 'src/utils/sleep';
import { dumpCreatePartTimeJobLeaveApplications } from 'src/utils/dumps/dumpCreatePartTimeJobLeaveApplications';
import { type IPartTimeJobsPayload } from 'src/api/LeaveApplicationTypes';
import { retryAsync } from 'ts-retry';
import { RETRY_GET_DOCS_CONFIG } from 'src/constants/errors/retry';
import { dumpSubmitApplicationProcess } from 'src/utils/dumps/dumpSubmitApplicationProcess';
import { dumpSubmitApplicationAction } from 'src/utils/dumps/dumpSubmitApplicationAction';
import { formatDateWithoutTimezone } from 'src/utils/date';
import { dumpApplicationDocuments } from 'src/utils/dumps/dumpApplicationDocuments';
import { dumpUpdateLeaveApplication } from 'src/utils/dumps/dumpUpdateLeaveApplication';
import { dumpUploadLeaveApplicationDocument } from 'src/utils/dumps/dumpUploadLeaveApplicationDocument';
import { LEAVE_APPLICATION_NAME, ORDER_NAME_SUFFIX } from 'src/constants/documentNames';
import { getBase64Img } from 'src/utils/image/getBase64Src';
import { ALLOWED_PREVIEW_TYPES } from 'src/constants/fileTypes';
import type { IProfile } from 'src/types/user/types';
import { GAEvents } from 'src/constants/gaEvents';
import { handleException } from '../utils';

export const getLeaveApplicationsList = createAsyncThunk('leaveApplications/list', async (_, thunkApi) => {
  try {
    const response = await api.leaveApplications.getList<ILeaveApplicationEntity[]>();

    return response.data;
  } catch (err: any) {
    handleException(err, thunkApi.dispatch);

    throw err;
  }
});

export const validateLeaveApplicationDates = createAsyncThunk(
  'leaveApplications/validateLeaveApplicationDates',
  async (formData: ILeaveApplicationFormData, thunkApi) => {
    try {
      const payload = dumpValidateLeaveApplication(formData);

      const response = await api.leaveApplications.validateDates<IValidateLeavePayload>(payload);

      return response.data;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplicationListWithDelay = createAsyncThunk(
  'leaveApplications/getSilently',
  async (_, thunkApi) => {
    try {
      await sleep(10000);

      const response = await api.leaveApplications.getList<ILeaveApplicationEntity[]>();

      return response.data;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplication = createAsyncThunk(
  'leaveApplications/getLeaveApplication',
  async (vacationId: number, thunkApi) => {
    try {
      const leaveApplication = await api.leaveApplications.get(vacationId);
      let admManager;
      let fnManager;

      if (leaveApplication.data.admManager) {
        admManager = await api.users.get(leaveApplication.data.admManager);
      }

      if (leaveApplication.data.fnManager) {
        fnManager = await api.users.get(leaveApplication.data.fnManager);
      }

      return {
        leaveApplication: leaveApplication.data,
        admManager: admManager?.data[0],
        fnManager: fnManager?.data[0]
      };
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getAllFullPartTimeLeaveApplications = createAsyncThunk(
  'leaveApplications/getAllFullPartTimeLeaveApplications',
  async (ids: number[], thunkApi) => {
    try {
      const promises = ids.map(async (id) => {
        const leaveApplication = await api.leaveApplications.get(id);
        let admManager;
        let fnManager;

        if (leaveApplication.data.admManager) {
          admManager = await api.users.get(leaveApplication.data.admManager);
        }

        if (leaveApplication.data.fnManager) {
          fnManager = await api.users.get(leaveApplication.data.fnManager);
        }

        return {
          ...leaveApplication.data,
          company: leaveApplication.data.companyName,
          position: leaveApplication.data.positionName,
          vacationType: leaveApplication.data.vacationName,
          admManager: admManager?.data[0].pib,
          fnManager: fnManager?.data[0].pib
        };
      });

      const data = await Promise.all(promises);

      return data;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

async function requestDocGeneration(applications: ILeaveApplicationEntity[]) {
  const submitPayloads = applications.map((item) =>
    dumpSubmitApplicationProcess(item.vacationId, 'Vacations\\Vacations_Generate_PDF')
  );

  const promises = submitPayloads.map(async (payload) => {
    return await api.leaveApplications.submitProcess(payload);
  });

  await Promise.all(promises);
}

const createMainLeaveApplication = async (
  formData: ILeaveApplicationFormData,
  userProfile: IProfile
) => {
  const mainLeaveApplicationPayload = dumpCreateLeaveApplication(formData);

  const newMainLeaveApplication = await api.leaveApplications.create<ICreateLeavePayload>(
    mainLeaveApplicationPayload
  );

  await requestDocGeneration([newMainLeaveApplication.data]);

  (window as any).dataLayer.push({
    event: GAEvents.CreateVacation,
    pib: userProfile.pib,
    company: formData.info.company.label,
    department: formData.info.department.value,
    position: formData.info.position,
    vacationType: formData.info.vacationType.label,
    dateFrom: formatDateWithoutTimezone(formData.info.dateFrom, 'YYYY-MM-DD'),
    dateTo: formatDateWithoutTimezone(formData.info.dateTo, 'YYYY-MM-DD')
  });

  return newMainLeaveApplication.data;
};

const updateMainLeaveApplication = async (formData: ILeaveApplicationFormData) => {
  const mainLeaveApplicationPayload = dumpUpdateLeaveApplication(formData);

  const newMainLeaveApplication = await api.leaveApplications.update<IUpdateLeavePayload>(
    mainLeaveApplicationPayload
  );

  await requestDocGeneration([newMainLeaveApplication]);

  return newMainLeaveApplication;
};

const createPartTimeJobLeaveApplications = async (
  formData: ILeaveApplicationFormData,
  mainLeaveApplicationId: number,
  userProfile: IProfile
) => {
  const partTimeJobLeaveApplicationsPayload = dumpCreatePartTimeJobLeaveApplications(
    formData,
    mainLeaveApplicationId
  );

  const promises = partTimeJobLeaveApplicationsPayload.map(async (itemPayload) => {
    const res = await api.leaveApplications.create<ICreateLeavePayload>(itemPayload);

    return res.data;
  });

  const partTimeLeaveApplications = await Promise.all(promises);

  if (partTimeLeaveApplications) {
    await requestDocGeneration(partTimeLeaveApplications);

    formData.partTimeJobs.items.forEach((item) => {
      (window as any).dataLayer.push(
        {
          event: GAEvents.CreateVacation,
          pib: userProfile.pib,
          company: item.company,
          department: item.department,
          position: item.position,
          vacationType: item.vacationType,
          dateFrom: formatDateWithoutTimezone(item.dateFrom, 'YYYY-MM-DD'),
          dateTo: formatDateWithoutTimezone(item.dateTo, 'YYYY-MM-DD')
        },
        userProfile
      );
    });
  }

  return partTimeLeaveApplications;
};

export const uploadLeaveApplicationDocuments = createAsyncThunk(
  'leaveApplications/uploadLeaveApplicationDocuments',
  async (payload: {
    documents: ILeaveApplicationFormData['documents'];
    leaveApplication: ILeaveApplicationEntity;
  }) => {
    const { documents, leaveApplication } = payload;
    const { vacationId } = leaveApplication;

    if (documents.fileList?.length) {
      const docsToUpload = documents.fileList.filter((file) => file.document.originFileObj);

      const leaveApplicationDocumentsPromises = docsToUpload.map(async (file) => {
        const uploadDocumentPayload = await dumpUploadLeaveApplicationDocument({
          vacationId,
          file: file.document
        });

        if (uploadDocumentPayload) await api.documents.upload(uploadDocumentPayload);

        return uploadDocumentPayload;
      });

      await Promise.all(leaveApplicationDocumentsPromises);

      const promises = docsToUpload.map(async (item) => {
        const url = await getBase64Img(item.document, ALLOWED_PREVIEW_TYPES);

        return {
          document: {
            uid: item.document.uid,
            name: item.document.name,
            preview: item.document.thumbUrl,
            url
          },
          vacationId
        };
      });

      const data = await Promise.all(promises);

      return data;
    }

    return [];
  }
);

export const submitLeaveApplication = createAsyncThunk(
  'leaveApplications/submit',
  async (formData: ILeaveApplicationFormData, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const userProfile = state.sessions.profile as IProfile;
      let partTimeLeaveApplications = [];
      let newMainLeaveApplication;
      const currentLeaveApplication = state.leaveApplications.currentLeaveApplication;
      const mainLeaveApplication = currentLeaveApplication.data;
      const needMainLeaveApplicationUpdate = currentLeaveApplication.needMainLeaveApplicationUpdate;
      const mainLeaveApplicationId = mainLeaveApplication?.vacationId;
      const mainUserCompanyIdentifier = state.sessions.profile?.companyIdentifier;
      const isMainUserCompanySelected = formData.info.company.value === mainUserCompanyIdentifier;

      const mainLeaveApplicationSingDocument = currentLeaveApplication.signDocuments.find(
        (item) => item.vacationId === mainLeaveApplicationId
      );

      if (!mainLeaveApplication) {
        newMainLeaveApplication = await createMainLeaveApplication(formData, userProfile);
      }

      if (
        (mainLeaveApplication && needMainLeaveApplicationUpdate) ||
        (mainLeaveApplication && !mainLeaveApplicationSingDocument)
      ) {
        newMainLeaveApplication = await updateMainLeaveApplication(formData);
      }

      if (formData.partTimeJobs && isMainUserCompanySelected) {
        partTimeLeaveApplications = await createPartTimeJobLeaveApplications(
          formData,
          mainLeaveApplicationId ?? newMainLeaveApplication?.vacationId,
          userProfile
        );
      }

      if (formData.documents.fileList?.length) {
        await thunkApi.dispatch(
          uploadLeaveApplicationDocuments({
            documents: formData.documents,
            leaveApplication: mainLeaveApplication ?? newMainLeaveApplication
          })
        );
      }

      const allLeaveApplications = [newMainLeaveApplication, ...partTimeLeaveApplications].filter(
        (i) => i
      );

      if (allLeaveApplications.length) {
        await thunkApi.dispatch(
          getLeaveApplicationSignDocument(
            allLeaveApplications.map((item) => item.vacationID || item.vacationId)
          )
        );
      }

      if (allLeaveApplications.length) {
        await thunkApi.dispatch(getLeaveApplicationsList());
      }

      return {
        mainLeaveApplication: newMainLeaveApplication,
        partTimeLeaveApplications
      };
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplicationSignDocument = createAsyncThunk(
  'leaveApplications/getSignDocuments',
  async (vacationIds: number[], thunkApi) => {
    try {
      await sleep(RETRY_GET_DOCS_CONFIG.delay);

      const promises: Array<Promise<ISignApplicationDocument>> = vacationIds.map(
        async (vacationId) => {
          const data = await retryAsync(
            async () => await api.documents.getSignApplication(vacationId),
            RETRY_GET_DOCS_CONFIG
          );

          return { vacationId, document: data.data };
        }
      );

      const signDocuments = await Promise.all(promises);
      const previewDocuments = await dumpApplicationDocuments(signDocuments);

      return {
        signDocuments,
        previewDocuments
      };
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const uploadSignedLeaveApplicationDocument = createAsyncThunk(
  'leaveApplications/uploadSignedDocument',
  async (payloadDocuments: IDumpSignedLeaveApplicationDocument[], thunkApi) => {
    try {
      const uploadPromises = payloadDocuments.map(async (document) => {
        const uploadPayload = dumpSignedApplicationDocument(document);

        await api.documents.update(uploadPayload);
      });

      await Promise.all(uploadPromises);
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const submitLeaveApplicationProcess = createAsyncThunk(
  'leaveApplications/submitLeaveApplicationProcess',
  async (vacationId: number, thunkApi) => {
    try {
      const payload = dumpSubmitApplicationProcess(vacationId, 'Vacations\\Vacations');

      await api.leaveApplications.submitProcess(payload);
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const submitLeaveApplicationsActions = createAsyncThunk(
  'leaveApplications/submitLeaveApplicationsActions',
  async ({ ids, stage }: { ids: number[], stage: number }, thunkApi) => {
    try {
      for (const id of ids) {
        const payload = dumpSubmitApplicationAction(id, stage);

        await api.leaveApplications.submitAction(payload);
      }
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplicationTypesList = createAsyncThunk(
  'leaveApplicationTypes/list',
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const profile = profileSelector(state);

      const data = await api.leaveApplicationTypes.getList({
        companyIdentifier: profile?.companyIdentifier ?? ''
      });

      return data;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getPartTimeJobs = createAsyncThunk(
  'leaveApplications/partTimeJobs',
  async (payload: ILeaveApplicationFormData, thunkApi) => {
    try {
      const requestPayload: IPartTimeJobsPayload = {
        companyIdentifier: payload.info.company.value as string,
        vacationIdentifier: payload.info.vacationType.value as string,
        dateFrom: formatDateWithoutTimezone(payload.info.dateFrom),
        dateTo: formatDateWithoutTimezone(payload.info.dateTo)
      };

      const partTimeJobs = await api.leaveApplicationTypes.getAdditionalList(requestPayload);

      return partTimeJobs;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplicationHistory = createAsyncThunk(
  'leaveApplications/history',
  async (vacationId: number, thunkApi) => {
    try {
      const data = await api.leaveApplicationHistory.getList(vacationId);

      return data;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const getLeaveApplicationDocuments = createAsyncThunk(
  'leaveApplications/documents',
  async (mainVacationId: number, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const partTimeLeaveApplications = state.leaveApplications.allItems.filter(
        (item) => item.parentRequestID_FK === mainVacationId
      );
      let partTimeJobsDocs: IApplicationDocumentData[] = [];
      const signDocuments: ISignApplicationDocument[] = [];

      const mainLeaveApplicationDocs = await api.documents.getLeaveApplicationDocuments(
        mainVacationId
      );

      mainLeaveApplicationDocs.data.forEach((document: IApplicationDocumentData) => {
        signDocuments.push({ vacationId: mainVacationId, document });
      });

      if (partTimeLeaveApplications.length) {
        const promises = partTimeLeaveApplications.map(async ({ vacationId }) => {
          return await api.documents.getLeaveApplicationDocuments(vacationId);
        });

        const res = await Promise.all(promises);

        res.forEach((item, index) => {
          item.data.forEach((document: IApplicationDocumentData) => {
            signDocuments.push({
              vacationId: partTimeLeaveApplications[index].vacationId,
              document
            });
          });

          partTimeJobsDocs = [...partTimeJobsDocs, ...item.data];
        });
      }

      const previewDocuments = await dumpApplicationDocuments(signDocuments);

      return {
        previewDocuments,
        signDocuments: signDocuments.filter(
          (item) =>
            item.document.name.includes(LEAVE_APPLICATION_NAME) ||
            item.document.name.includes(ORDER_NAME_SUFFIX)
        )
      };
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);

export const deletePartTimeApplications = createAsyncThunk(
  'leaveApplications/deletePartTimeApplications',
  async ({ ids, isShowNotification = true }: { ids: number[], isShowNotification?: boolean }, thunkApi) => {
    try {
      for (const id of ids) {
        await api.leaveApplications.delete(id);
      }

      if (isShowNotification) {
        showNotification({
          type: NOTIFICATION_TYPE.SUCCESS,
          message: 'Увага',
          description: 'Сумісні заявки - видалені'
        });
      }

      return ids;
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);
