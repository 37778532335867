import { ThemeProvider as Provider } from 'styled-components';
import { theme } from 'antd';
import React from 'react';
import { Colors } from './defaultTheme';

export default function ThemeProvider({ children }: React.PropsWithChildren) {
  const { token } = theme.useToken();

  return (
    <Provider theme={{ antd: token, colors: Colors }}>
      {children}
    </Provider>
  );
}
