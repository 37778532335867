import { type NavigateFunction } from 'react-router';

let navigate: NavigateFunction;

export const initializeNavigator = (n: NavigateFunction) => {
  navigate = n;
};

export const navigateTo = (path: string) => {
  navigate(path);
};
