/* eslint-disable @typescript-eslint/ban-ts-comment */
import queryString from 'query-string';
import { createFormData } from 'src/utils/formDataUtils';

import { errorsCodes } from 'src/constants/errors/codes';
import { ROUTES } from 'src/constants/routes';

import config from '../config';

interface IRequest {
  url: string;
  method?: string;
  headers?: HeadersInit;
  params?: any;
  body?: any;
  formData?: any;
}

const { SESSION_REQUIRED, SERVER_ERROR } = errorsCodes;

const transformErrorFields = (
  errorFields: Array<{ path: string[]; type: string }> | undefined
): Record<string, string> => {
  if (!errorFields) return {};

  return errorFields.reduce<Record<string, string>>((transformedFields, errorField) => {
    const key =
      errorField.path.length > 1 ? `data/${errorField.path.join('/')}` : errorField.path[0];
    transformedFields[key] = errorField.type;

    return transformedFields;
  }, {});
};

export default class ApiClient {
  prefix: string;
  token: string;

  constructor({ prefix = 'v1/' } = {}) {
    this.prefix = prefix;

    this.token = '';
  }

  async get(url: string, params: HeadersInit) {
    return await this.request({
      url,
      params,
      method: 'GET'
    });
  }

  async post(url: string, payload = {}) {
    return await this.request({
      url,
      method: 'POST',
      body: payload
    });
  }

  async postFile(url: string, file?: any) {
    const formData = new FormData();

    formData.append('file', file);

    return await this.request({
      url,
      method: 'POST',
      formData
    });
  }

  async postFormData(url: string, data?: any) {
    const formData = createFormData(data);

    return await this.request({
      url,
      method: 'POST',
      formData
    });
  }

  async patchFormData(url: string, data?: any) {
    const formData = createFormData(data);

    return await this.request({
      url,
      method: 'PATCH',
      formData
    });
  }

  async put(url: string, payload = {}) {
    return await this.request({
      url,
      method: 'PUT',
      body: payload
    });
  }

  async patch(url: string, payload = {}) {
    return await this.request({
      url,
      method: 'PATCH',
      body: payload
    });
  }

  async delete(url: string, payload = {}) {
    return await this.request({
      url,
      method: 'DELETE',
      body: payload
    });
  }

  fetch = async ({ url, method, headers, params, body }: IRequest) => {
    const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';

    return await fetch(`${config.apiUrl}${this.prefix}${url}${query}`, {
      method,
      headers,
      credentials: 'include',
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      crossDomain: false,
      body
    });
  };

  async request({ url, method, params = {}, body, formData }: IRequest) {
    try {
      const requestHeaders: HeadersInit = {
        'Cache-Control': 'no-cache',
        pragma: 'no-cache',
        ...(!formData ? { 'Content-Type': 'application/json' } : {})
      };

      if (this.token) {
        requestHeaders.Authorization = this.token;
      }

      const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';

      const response = await fetch(`${config.apiUrl}${this.prefix}${url}${query}`, {
        method,
        headers: requestHeaders,
        credentials: 'include',
        body: formData ?? JSON.stringify(body)
      });

      return await this.processResponse({ url, response });
    } catch (e) {
      if (e instanceof Error) {
        const errorResponse = {
          code: SERVER_ERROR
        };

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorResponse;
      }

      throw e;
    }
  }

  async processResponse({ url, response }: { url: string; response?: any }) {
    const result = await response.json();

    if (result.status === 0) {
      const { code, fields, data, message } = result.error;

      const errorsStatuses = [SESSION_REQUIRED];

      if (errorsStatuses.includes(code)) {
        // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
        // @ts-ignore
        window.location = ROUTES.LOGIN;
      }

      const errorResponse = {
        code,
        fields: transformErrorFields(fields),
        data,
        message
      };

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errorResponse;
    }

    return result;
  }

  setToken(token: string) {
    this.token = token;
  }
}
