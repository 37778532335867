/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LEAVE_APPLICATION_NAME, ORDER_NAME_SUFFIX } from 'src/constants/documentNames';
import { ROUTES } from 'src/constants/routes';
import { LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';
import { useAppDispatch } from 'src/hooks';
import { useModal } from 'src/hooks/useModal';
import {
  cleanUpCurrentLeaveApplication,
  getLeaveApplicationListWithDelay,
  setSignDocumentsModalOpen,
  submitLeaveApplicationProcess,
  uploadSignedLeaveApplicationDocument,
  submitLeaveApplicationsActions
} from 'src/store/leaveApplications';
import { type ICurrentLeaveApplication } from 'src/store/leaveApplications/types';
import { ModalType } from 'src/store/modals/types';
import {
  type IPreviewDocument,
  type ILeaveApplicationFormData,
  type ISignApplicationDocument,
  type SignLeaveApplicationRecord,
  type ILeaveApplicationEntity,
  type IPartTimeJobEntity
} from 'src/types/leaveApplications/types';
import { formatDateWithoutTimezone } from 'src/utils/date';
import { signPdfFile } from 'src/utils/signPdfFile';

function prepareDataForSigningModal(
  formData: ILeaveApplicationEntity,
  partTimeJobs: IPartTimeJobEntity[],
  previewDocuments: IPreviewDocument[]
): SignLeaveApplicationRecord[] {
  const mainLeaveApplicationData = {
    key: '1',
    dataIndex: '1',
    dateFrom: formatDateWithoutTimezone(formData.dateFrom),
    dateTo: formatDateWithoutTimezone(formData.dateTo),
    vacationName: formData.vacationName,
    usedDays: formData.usedDays,
    positionName: formData.positionName,
    document: previewDocuments.find(preview => preview.vacationId === formData.vacationId)?.document as IPreviewDocument['document']
  };

  const partTimeJobsData = partTimeJobs
    .filter((item) => item.vacationId)
    .map((item) => ({
      key: item.id,
      dataIndex: item.id,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      vacationName: item.vacationType,
      usedDays: item.usedDays,
      positionName: item.position,
      document: previewDocuments.find(preview => preview.vacationId === item.vacationId)?.document as IPreviewDocument['document']
    }));

  return [mainLeaveApplicationData, ...partTimeJobsData];
}

function filterOrders<T>(documents: Array<T & { document: { name: string } }>) {
  return documents.filter((item) => item.document.name.includes(ORDER_NAME_SUFFIX) && !item.document.name.includes(LEAVE_APPLICATION_NAME));
}

function filterApplications<T>(documents: Array<T & { document: { name: string } }>) {
  return documents.filter((item) => item.document.name.includes(LEAVE_APPLICATION_NAME));
}

function prepareTitleForSigningModal(status: LeaveApplicationStatus, docLength: number) {
  if (status === LeaveApplicationStatus.familiarizationWithOrder) {
    if (docLength > 1) {
      return 'Групове підписання наказів';
    }
    return 'Підписання наказу';
  }

  if (docLength > 1) {
    return 'Групове підписання заявок';
  }

  return 'Підписання заявки';
}

interface IProps {
  formData: ILeaveApplicationFormData;
  currentLeaveApplication: ICurrentLeaveApplication;
}

export const useSignApplication = ({ formData, currentLeaveApplication }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openSignApplicationModal, closeSignApplicationModal, updateSignApplicationModal] = useModal(ModalType.SignLeaveApplication);
  const [openConfirmCancelModal, closeConfirmCancelModal] = useModal(ModalType.ConfirmCancelModal);

  const handleCancel = useCallback(() => {
    openConfirmCancelModal({
      title: 'Скасувати підписання',
      text: 'Ви впевнені, що хочете скасувати підписання заявки? Усі введені дані будуть втрачені, і заявка не буде збережена',
      onSubmit: () => {
        navigate(ROUTES.LEAVE_APPLICATIONS);
        dispatch(setSignDocumentsModalOpen(false));
        closeSignApplicationModal();
        closeConfirmCancelModal();
      },
      onClose: closeConfirmCancelModal
    });
  }, [navigate, openConfirmCancelModal, closeConfirmCancelModal, closeSignApplicationModal]);

  const [openIsSignedApplicationModal, closeIsSignedApplicationModal] = useModal(
    ModalType.LeaveApplicationIsSigned
  );

  const handleCloseIsSignedApplicationModal = useCallback(() => {
    navigate(ROUTES.LEAVE_APPLICATIONS);
    closeIsSignedApplicationModal();
    dispatch(cleanUpCurrentLeaveApplication());
  }, [navigate, dispatch, closeIsSignedApplicationModal]);

  const handleSignDocuments = useCallback(
    (signDocuments: ISignApplicationDocument[]) => async (signature: string) => {
      let signaturePosition = { x: 50, y: 200 };

      if (currentLeaveApplication.data && LeaveApplicationStatusId[currentLeaveApplication.data?.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
        signaturePosition = { x: 50, y: 90 };
      }
      const uploadSignedDocumentsPayloadPromises = signDocuments.map(async (signDocument) => {
        const signedDocument = await signPdfFile(signDocument.document.content, signature, signaturePosition);

        // TODO: remove after testing stage
        // const link = document.createElement('a');
        // link.style.display = 'none';
        // link.href = signedDocument;
        // link.download = 'Signed file';
        // document.body.appendChild(link);
        // link.click();

        return {
          vacationId: signDocument.vacationId,
          filePath: signDocument.document.filePath,
          document: signedDocument
        };
      });

      const uploadSignedDocumentsPayload = await Promise.all(uploadSignedDocumentsPayloadPromises);

      dispatch(uploadSignedLeaveApplicationDocument(uploadSignedDocumentsPayload));
    },
    [dispatch, currentLeaveApplication]
  );

  const handleOpenSignApplicationModal = useCallback(
    (data: SignLeaveApplicationRecord[], signDocuments: ISignApplicationDocument[], title: string) => {
      openSignApplicationModal({
        data,
        title,
        isLoading: currentLeaveApplication.isLoading,
        onSubmit: handleSignDocuments(signDocuments),
        onClose: () => {
          handleCancel();
        }
      });
    },
    [handleCancel, handleSignDocuments, currentLeaveApplication, formData.info]
  );

  useEffect(() => {
    if (
      currentLeaveApplication.signDocumentsModalOpen &&
      currentLeaveApplication.signDocuments.length &&
      currentLeaveApplication.data &&
      !currentLeaveApplication.isLeaveApplicationSigned
    ) {
      const { signDocuments, previewDocuments, data, partTimeJobs } = currentLeaveApplication;
      const status = LeaveApplicationStatusId[formData.info.statusId];

      if (LeaveApplicationStatusId[data.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
        const documentsToSign = filterOrders(signDocuments);
        const documentsToPreview = filterOrders(previewDocuments);
        const signingData = prepareDataForSigningModal(data, partTimeJobs.items, documentsToPreview);
        const title = prepareTitleForSigningModal(status, documentsToSign.length);

        handleOpenSignApplicationModal(signingData, documentsToSign, title);

        return;
      }

      const documentsToSign = filterApplications(signDocuments);
      const documentsToPreview = filterApplications(previewDocuments);
      const signingData = prepareDataForSigningModal(data, partTimeJobs.items, documentsToPreview);
      const title = prepareTitleForSigningModal(status, documentsToSign.length);

      handleOpenSignApplicationModal(signingData, documentsToSign, title);
    }
  }, [
    currentLeaveApplication.signDocuments,
    currentLeaveApplication.isLeaveApplicationSigned,
    currentLeaveApplication.signDocumentsModalOpen
  ]);

  useEffect(() => {
    const { isLeaveApplicationSigned, data, partTimeJobs } = currentLeaveApplication;

    if (isLeaveApplicationSigned && data) {
      if (LeaveApplicationStatusId[data.statusId] === LeaveApplicationStatus.rework) {
        const mainLeaveApplicationId = data.vacationId;
        const reworkStage = 0;

        dispatch(submitLeaveApplicationsActions({ ids: [mainLeaveApplicationId], stage: reworkStage }));
        dispatch(getLeaveApplicationListWithDelay());

        return;
      }

      if (LeaveApplicationStatusId[data.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
        const mainLeaveApplicationId = data.vacationId;
        const partTimeJobApplicationsIds = partTimeJobs.items.map(i => i.vacationId).filter(i => i) as number[];
        const ids = [mainLeaveApplicationId, ...partTimeJobApplicationsIds];
        const signingStage = 2;

        dispatch(submitLeaveApplicationsActions({ ids, stage: signingStage }));

        return;
      }

      dispatch(submitLeaveApplicationProcess(data.vacationId));
      dispatch(getLeaveApplicationListWithDelay());
    }
  }, [currentLeaveApplication.isLeaveApplicationSigned]);

  useEffect(() => {
    updateSignApplicationModal({ isLoading: currentLeaveApplication.isLoading });
  }, [currentLeaveApplication.isLoading, updateSignApplicationModal]);

  useEffect(() => {
    const { isLeaveApplicationProcessSubmitted, isLeaveApplicationActionSubmitted, data } = currentLeaveApplication;

    if (isLeaveApplicationProcessSubmitted || isLeaveApplicationActionSubmitted) {
      closeSignApplicationModal();

      let modalText = 'Очікуйте смс-повідомлення на Ваш телефон, щодо ознайомлення з наказом. Лише після ознайомлення з наказом, відпустка рахується оформленою';

      if (data && LeaveApplicationStatusId[data.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
        modalText = 'Дякуємо, Ваша заявка була успішно оформлена';
      }

      openIsSignedApplicationModal({
        onSubmit: handleCloseIsSignedApplicationModal,
        onClose: handleCloseIsSignedApplicationModal,
        text: modalText
      });
    }
  }, [
    currentLeaveApplication.isLeaveApplicationProcessSubmitted,
    currentLeaveApplication.isLeaveApplicationActionSubmitted,
    closeSignApplicationModal,
    handleCloseIsSignedApplicationModal,
    openIsSignedApplicationModal
  ]);

  return [handleOpenSignApplicationModal];
};
