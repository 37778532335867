import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  InboxOutlined,
  WarningOutlined,
  FolderViewOutlined,
  ProjectOutlined,
  RedoOutlined,
  FormOutlined,
  FileSearchOutlined,
  LoadingOutlined
} from 'src/components/Icons';

// WHEN 1 THEN N'Проект'
// WHEN 2 THEN N'Очікує погодження адміністративного/функціонального керівника'
// WHEN 3 THEN N'На доопрацюванні'
// WHEN 4 THEN N'Формування наказу'
// WHEN 5 THEN N'Наказ підписано'
// WHEN 6 THEN N'Архів'
// WHEN 7 THEN N'Відхилено''
// WHEN 8 THEN N'Підписання заяви'
// WHEN 9 THEN N'Погодження співробітником фронт-офісу СЦКА'
// WHEN 10 THEN N'Погодження співробітником бек-офісу СЦКА'
// WHEN 11 THEN N'Підписання наказу'
// WHEN 12 THEN N'Ознайомлення наказу співробітником'
// WHEN 13 THEN N'Працівнику було надано наказ на ознайомлення'
// WHEN 14 THEN N'Наказ підписано та ознайомлено'

export enum LeaveApplicationStatus {
  'default' = 'default',
  'project' = 'project',
  'managerApprovalWaiting' = 'managerApprovalWaiting',
  'rework' = 'rework',
  'orderCreation' = 'orderCreation',
  'orderSigned' = 'orderSigned',
  'archive' = 'archive',
  'rejected' = 'rejected',
  'atSigning' = 'atSigning',
  'frontOfficeApproval' = 'frontOfficeApproval',
  'backOfficeApproval' = 'backOfficeApproval',
  'orderSigning' = 'orderSigning',
  'familiarizationWithOrder' = 'familiarizationWithOrder',
  'orderSentToUser' = 'orderSentToUser',
  'orderSignedAndInformed' = 'orderSignedAndInformed',
  'pending' = 'pending'
}

export const LeaveApplicationStatusId: Record<number, LeaveApplicationStatus> = {
  0: LeaveApplicationStatus.default,
  1: LeaveApplicationStatus.project,
  2: LeaveApplicationStatus.managerApprovalWaiting,
  3: LeaveApplicationStatus.rework,
  4: LeaveApplicationStatus.orderCreation,
  5: LeaveApplicationStatus.orderSigned,
  6: LeaveApplicationStatus.archive,
  7: LeaveApplicationStatus.rejected,
  8: LeaveApplicationStatus.atSigning,
  9: LeaveApplicationStatus.frontOfficeApproval,
  10: LeaveApplicationStatus.backOfficeApproval,
  11: LeaveApplicationStatus.orderSigning,
  12: LeaveApplicationStatus.familiarizationWithOrder,
  13: LeaveApplicationStatus.orderSentToUser,
  14: LeaveApplicationStatus.orderSignedAndInformed,
  // extra status frontend only
  100: LeaveApplicationStatus.pending
}

export const LeaveApplicationStatusData = {
  [LeaveApplicationStatus.default]: {
    icon: WarningOutlined,
    text: '-'
  },
  [LeaveApplicationStatus.project]: {
    icon: ProjectOutlined,
    text: 'Проект'
  },
  [LeaveApplicationStatus.managerApprovalWaiting]: {
    icon: ClockCircleOutlined,
    text: 'Очікує погодження'
  },
  [LeaveApplicationStatus.rework]: {
    icon: RedoOutlined,
    text: 'На доопрацюванні'
  },
  [LeaveApplicationStatus.orderCreation]: {
    icon: FormOutlined,
    text: 'Формування наказу'
  },
  [LeaveApplicationStatus.orderSigned]: {
    icon: CheckCircleOutlined,
    text: 'Наказ підписано'
  },
  [LeaveApplicationStatus.archive]: {
    icon: InboxOutlined,
    text: 'Архів'
  },
  [LeaveApplicationStatus.rejected]: {
    icon: CloseCircleOutlined,
    text: 'Відхилено'
  },
  [LeaveApplicationStatus.atSigning]: {
    icon: EditOutlined,
    text: 'Підписання заявки'
  },
  [LeaveApplicationStatus.frontOfficeApproval]: {
    icon: CheckCircleOutlined,
    text: 'Погодження фронт-офісом'
  },
  [LeaveApplicationStatus.backOfficeApproval]: {
    icon: CheckCircleOutlined,
    text: 'Погодження бек-офісом'
  },
  [LeaveApplicationStatus.orderSigning]: {
    icon: EditOutlined,
    text: 'Підписання наказу'
  },
  [LeaveApplicationStatus.familiarizationWithOrder]: {
    icon: FileSearchOutlined,
    text: 'Ознайомлення з наказом'
  },
  [LeaveApplicationStatus.orderSentToUser]: {
    icon: FolderViewOutlined,
    text: 'Надано наказ на ознайомлення'
  },
  [LeaveApplicationStatus.orderSignedAndInformed]: {
    icon: CheckCircleOutlined,
    text: 'Наказ підписано та ознайомлено'
  },
  [LeaveApplicationStatus.pending]: {
    icon: LoadingOutlined,
    text: 'В обробці'
  }
}

export const ALLOWED_TO_EDIT_STATUSES = [
  LeaveApplicationStatus.project,
  LeaveApplicationStatus.rework,
  LeaveApplicationStatus.familiarizationWithOrder
];
