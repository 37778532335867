import Base from './Base';
import { type ILeaveApplicationHistory } from 'src/types/history/types';
import { handleRequest } from 'src/utils/request';

class LeaveApplicationHistory extends Base {
  getList = async (vacationId: number): Promise<ILeaveApplicationHistory[]> => {
    const { data } = await handleRequest(this.apiClient.get(`vacation-history/${vacationId}`));

    return data;
  };
}

export default LeaveApplicationHistory;
