import React from 'react';
import { Button, Modal, Space, Typography } from 'antd';
import { type UserIdleModalProps } from './UserIdleModalProps';
import Countdown from 'src/components/Countdown/Countdown';
import styled from 'styled-components';

const COUNTDOWN_DURATION_SECONDS = 30;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const UserIdleModal: React.FC<UserIdleModalProps> = ({ onClose, onLogout }) => {
  return (
    <Modal
      data-testid='LogoutTimerModal'
      open
      closeIcon={false}
      width={400}
      footer={[]}
    >
      <Container>
        <Typography.Title level={4}>Ви ще тут?</Typography.Title>
        <Countdown countdownDuration={COUNTDOWN_DURATION_SECONDS} onComplete={onLogout} />
      </Container>
      <Container>
        <Space size='large'>
          <Button size='large' onClick={onLogout}>Закінчити</Button>
          <Button type='primary' size='large' onClick={onClose}>Продовжити</Button>
        </Space>
      </Container>
    </Modal>
  );
};
