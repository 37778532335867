import moment, { type Moment } from 'moment';
import { TIME_ZONE } from 'src/constants/timezone';

export function momentDate(date: string, format = '') {
  return moment(date, format);
}

export function dateToTimeZone(date: Moment) {
  return date.tz(TIME_ZONE);
}

export function formatDate(date: string | Moment, format = 'DD.MM.YYYY') {
  return dateToTimeZone(moment(date)).format(format);
}

export function formatDateWithoutTimezone(date: string | Moment, format = 'YYYY-MM-DDTHH:mm:ss') {
  return dateToTimeZone(moment(date)).format(format);
}

export function formatMomentDate(date: Moment, format = 'DD.MM.YYYY') {
  return date.format(format);
}

export function dateToISO(date: Moment) {
  return date.toISOString();
}

export function isValidDate(date: Moment) {
  return date.isValid();
}

export function isDateInFuture(date: Moment) {
  return date?.isAfter(moment());
}

export function formatCountdown(countdownDuration: number, elapsedTime: number) {
  const duration = moment.duration(countdownDuration - elapsedTime, 'seconds');
  const minutes = duration.minutes();
  const seconds = duration.seconds().toString().length === 1 ? `0${duration.seconds().toString()}` : duration.seconds();

  return `${minutes}:${seconds}`;
}

export function getLeaveApplicationDateDiff(dateFrom: Moment, dateTo: Moment) {
  return dateTo.diff(dateFrom, 'days') + 1;
}
