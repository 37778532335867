import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import React from 'react';
import { ReactComponent as EmptyStateIcon } from 'src/assets/images/empty-state.svg';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
`;

interface IProps {
  onCreate: () => void;
}

export const LeaveApplicationsPlaceholder: React.FC<IProps> = ({ onCreate }) => {
  return (
    <Flex align="center" justify="center">
      <Flex
        vertical
        align="center"
        gap={24}
        style={{ maxWidth: 375, width: '100%', textAlign: 'center' }}
      >
        <EmptyStateIcon />
        <div>
          <Typography.Title level={4} style={{ marginBottom: 16 }}>
            На жаль, наразі у Вас ще немає створених заявок
          </Typography.Title>
          <Typography.Text type="secondary" style={{ fontSize: 16 }}>
            Почніть створення нової заявки, щоб розпочати використання нашого сервісу
          </Typography.Text>
        </div>
        <StyledButton
          data-testid="createLeaveButton"
          size="large"
          icon={<PlusOutlined />}
          onClick={onCreate}
        >
          Створити заявку
        </StyledButton>
      </Flex>
    </Flex>
  );
};
