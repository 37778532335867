import React, { useRef, useEffect, useCallback } from 'react';
import { Input, Form, type InputProps } from 'antd';
import cx from 'classnames';

import { IndicatePassword } from '../../IndicatePassword/IndicatePassword';

import { applyMask, type maskType } from 'src/utils/masks';

import styles from './input.module.scss';
import { HelpText } from 'src/components/Inputs/Input/HelpText/HelpText';
import { ErrorText } from './ErrorText/ErrorText';
import styled from 'styled-components';

const StyledInput = styled(Input)<{ isError?: boolean }>`
  color: ${(props) => props.isError ? props.theme.antd.colorErrorText : ''}
`;

interface IProps {
  value?: string;
  maskType?: maskType;
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  onChange?: (value: string, name: string) => void;
  helpText?: string | object[];
  errors?: string | object[];
  passIndicator?: object;
  disabled?: boolean;
  className?: string;
}

const CustomInput: React.FC<IProps | (InputProps & Omit<IProps, ''>)> = (props) => {
  const {
    value = '',
    label = '',
    type = 'text',
    name = '',
    placeholder = '',
    maskType = '',
    autoFocus = false,
    required = false,
    className,
    onChange,
    helpText = null,
    errors = null,
    passIndicator = {},
    disabled = false
  } = props;

  const inputRef = useRef<any>();

  const updateInput = useCallback(() => {
    if (maskType) {
      applyMask(maskType, inputRef.current.input);
    }
  }, [maskType]);

  useEffect(() => {
    updateInput();
    if (autoFocus && inputRef.current) inputRef.current.focus();
  }, [updateInput, autoFocus]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value, name);
      }
    },
    [onChange, name]
  );

  const getInputMessage = (msg: string | object[]) => {
    switch (typeof msg) {
      case 'string':
        return msg;
      case 'object':
        return msg.join('; ');
      default:
        return null;
    }
  };

  const inputStyles = cx({ [styles.input]: true, [className ?? '']: className });

  return (
    <Form.Item
      label={label}
      required={required}
      style={{ display: 'flex', flexDirection: 'column' }}
      validateStatus={errors?.length ? 'error' : 'validating'}
      help={errors?.length
        ? <ErrorText>{getInputMessage(errors ?? '')}</ErrorText>
        : helpText ? <HelpText>{getInputMessage(helpText ?? '')}</HelpText> : ''
      }
    >
      <StyledInput
        {...props}
        type={type}
        value={value}
        name={name}
        ref={inputRef}
        onChange={handleChange}
        placeholder={placeholder}
        className={inputStyles}
        disabled={disabled}
        autoComplete="off"
        isError={Boolean(errors?.length)}
      />
      {passIndicator ? <IndicatePassword rules={passIndicator} /> : null}
    </Form.Item>
  );
};

export { CustomInput as Input };
