import type { IDocumentUploadPayload, IUpdatedDocumentPayload } from 'src/types/leaveApplications/types';
import Base from './Base';
import { handleRequest } from 'src/utils/request';

class Documents extends Base {
  upload = async (payload: IDocumentUploadPayload): Promise<void> => {
    await handleRequest(this.apiClient.post('documents/upload', payload));
  };

  update = async (payload: IUpdatedDocumentPayload): Promise<void> => {
    await handleRequest(this.apiClient.patch('documents/update', payload));
  };

  getSignApplication = async (vacationId: number) => {
    return await handleRequest(this.apiClient.get(`documents/signVacation/${vacationId}`));
  };

  getLeaveApplicationDocuments = async (vacationId: number) => {
    return await handleRequest(this.apiClient.get(`documents/list/${vacationId}`));
  };
}

export default Documents;
