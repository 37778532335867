import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router';

import { Row, Col } from 'src/components';
import { CENTER, MIDDLE } from 'src/constants/components/row';

import leaveApplicationLogo from 'src/assets/images/leave-application-logo.svg';
import { ROUTES } from 'src/constants/routes';

const { Title } = Typography;

const Content = styled.div`
  height: calc(100vh - 144px);
  box-shadow: ${props => props.theme.antd.boxShadow};
  border-radius: ${props => props.theme.antd.borderRadius}px;
  background: ${props => props.theme.antd.colorBgBase};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const Wrapper = styled.div`
  width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LeaveApplicationLogo = styled.img`
  width: 150px;
`;

export const Main: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.LEAVE_APPLICATIONS);
  };

  return (
    <Row data-testid='MainPage' justify={CENTER} align={MIDDLE}>
      <Col xs={24}>
        <Content>
          <Wrapper>
            <LeaveApplicationLogo src={leaveApplicationLogo} />
            <Title level={3}>Відпустка</Title>
            <Button size='large' block onClick={handleClick}>Перейти</Button>
          </Wrapper>
        </Content>
      </Col>
    </Row>
  );
};
