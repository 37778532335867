import React from 'react';
import { Modal, Typography } from 'antd';
import { type ErrorModalProps } from './ErrorModalProps';
import { ReactComponent as AlertIcon } from 'src/assets/images/alert.svg';
import styled from 'styled-components';
import { useModal } from 'src/hooks/useModal';
import { ModalType } from 'src/store/modals/types';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 24px;
  text-align: center;
`;

export const ErrorModal = ({ title, message }: ErrorModalProps) => {
  const [_, closeModal] = useModal(ModalType.ErrorModal);

  return (
    <Modal
      data-testid='ErrorModal'
      open
      centered
      title={title}
      onOk={() => { closeModal() }}
      onCancel={() => { closeModal() }}
      okText='Зрозуміло'
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Content>
        <AlertIcon style={{ width: 50, height: 50 }} />
        <Typography.Text style={{ fontSize: '16px' }}>
          {message}
        </Typography.Text>
      </Content>
    </Modal>
  );
};
