import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Grid, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { StackTable } from 'src/components/StackTable/StackTable';
import { Status } from 'src/components/Status/Status';
import { LeaveApplicationStatus, LeaveApplicationStatusData, LeaveApplicationStatusId } from 'src/constants/status';
import { type ILeaveApplicationPagination, type ILeaveApplicationEntity } from 'src/types/leaveApplications/types';
import { ActionColumn } from './components/ActionColumn';
import { formatDate } from 'src/utils/date';
import { LeaveApplicationStatusColors } from 'src/features/theme';
const { useBreakpoint } = Grid;

const StyledTable = styled(Table)<any>`
  .ant-table-cell {
    padding: 14px !important;
  }

  .ant-table-row > td {
    font-size: ${(props) => (props.screen.xl ? '16px' : '14px')};
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

interface IProps {
  data: ILeaveApplicationEntity[],
  pagination: ILeaveApplicationPagination | null;
  pendingApplicationId: number | null;
  onEdit: (record: ILeaveApplicationEntity) => (e: React.MouseEvent<HTMLElement>) => void;
  onRowClick: (record: ILeaveApplicationEntity) => (e: React.MouseEvent<HTMLElement>) => void;
  onPaginationChange: ({ pageNumber }: { pageNumber: number }) => void;
}

export const LeaveApplicationTable: React.FC<IProps> = ({ data, pagination, pendingApplicationId, onEdit, onRowClick, onPaginationChange }) => {
  const screen = useBreakpoint();
  const isDesktop = !!screen.md;

  const columns: ColumnsType<ILeaveApplicationEntity> = useMemo(
    () => [
      {
        title: '№',
        key: 'vacationId',
        dataIndex: 'vacationId'
      },
      {
        title: 'Вид відпустки',
        key: 'vacationName',
        dataIndex: 'vacationName'
      },
      {
        title: 'Початок періоду',
        key: 'dateFrom',
        dataIndex: 'dateFrom',
        render: (_, record) => <span>{formatDate(record.dateFrom)}</span>
      },
      {
        title: 'Кінець періоду',
        key: 'dateTo',
        render: (_, record) => <span>{formatDate(record.dateTo)}</span>
      },
      {
        title: 'Кількість днів',
        key: 'usedDays',
        dataIndex: 'usedDays'
      },
      {
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        render: (_, record) => {
          let statusName = LeaveApplicationStatusId[record.statusId];

          if (pendingApplicationId === record.vacationId) {
            statusName = LeaveApplicationStatus.pending;
          }

          const colors = statusName ? LeaveApplicationStatusColors[statusName] : LeaveApplicationStatusColors.default;
          const { icon: Icon, text } = statusName ? LeaveApplicationStatusData[statusName] : LeaveApplicationStatusData.default;

          return <Status colors={colors} statusText={text} icon={<Icon style={{ fontSize: '16px' }} />} />
        }
      },
      {
        title: 'Дії',
        key: 'action',
        dataIndex: 'action',
        align: 'right',
        render: (_, record) => (
          <ActionColumn record={record} pendingApplicationId={pendingApplicationId} onEdit={onEdit} />
        )
      }
    ],
    [onEdit, pendingApplicationId]
  );

  const handleOnRowClick = (e: React.MouseEvent<HTMLElement>, record: ILeaveApplicationEntity, rowIndex?: number) => {
    onRowClick(record)(e);
  }

  const onRow = (record: ILeaveApplicationEntity, rowIndex: number) => {
    return {
      onClick: (e: React.MouseEvent<HTMLElement>) => { handleOnRowClick(e, record, rowIndex) }
    };
  }

  return (
    <TableContainer>
      {isDesktop
        ? <StyledTable
            columns={columns}
            dataSource={data}
            screen={screen}
            onRow={onRow}
            rowKey={(record: ILeaveApplicationEntity) => record.vacationId}
            pagination={{
              showSizeChanger: false,
              total: pagination?.totalCount,
              pageSize: pagination?.pageSize,
              current: pagination?.currentPage,
              onChange: (pageNumber: number) => {
                onPaginationChange({ pageNumber });
              }
            }}
        />
        : <StackTable
            columns={columns}
            pagination={pagination}
            dataSource={data}
            isPagination
            onRow={handleOnRowClick}
            onPaginationChange={(pageNumber: number) => {
              onPaginationChange({ pageNumber });
            }}
          />
      }
    </TableContainer>
  );
};
