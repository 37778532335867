import React, { useCallback, useState } from 'react';
import { Col, Row, Typography, Space, Menu, type MenuProps, Button, Grid, Drawer } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import logo from 'src/assets/images/mhp-logo.png';
import avatar from 'src/assets/images/avatar.svg';
import { ReactComponent as ExitIcon } from 'src/assets/images/exit.svg';
import { START, MIDDLE } from 'src/constants/components/row';
import { MenuOutlined } from 'src/components';
import { ROUTES } from 'src/constants/routes';
import { type ValueOf } from 'src/types/common';
import { logout, profileSelector } from 'src/store/sessions';
import { type AppDispatch } from 'src/store/rootStore';
import { ModalType } from 'src/store/modals/types';
import { useModal } from 'src/hooks/useModal';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const StyledRow = styled(Row)<{ $isMobile: boolean }>`
  height: ${props => props.$isMobile ? '66px' : '96px'};
  box-shadow: ${(props) => props.theme.antd.boxShadow};
  background:  ${(props) => props.theme.antd.colorBgBase};
  position: fixed;
  width: 100%;
  z-index: 1060;
  top: 0;
`;

const Logo = styled.img`
  height: 28px;
`;

const Stub = styled.div<{ $isMobile: boolean }>`
  height: ${props => props.$isMobile ? '66px' : '96px'};
`;

const StyledMenuOutlined = styled(MenuOutlined)<{ $isMobile: boolean }>`
  display: ${props => props.$isMobile ? 'inline-flex' : 'none'};
  font-size: 22px;
  margin-right: 24px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RightSideWrapper = styled.div``;

const MenuContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  display: ${props => props.$isMobile ? 'none' : 'block'} 
`;

const StyledMenu = styled(Menu)`
  border-bottom: none;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
`;

const NameContainer = styled.div<{ $isMobile: boolean }>`
  display: ${props => props.$isMobile ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-end;
  width: 300px;
  text-align: right;
`;

const StyledMenuItem = styled.div<{ $isActive: boolean }>`
  display: inline-flex;
  border-bottom: ${props => props.$isActive ? '2px solid #000;' : 'none'};
  cursor: pointer;
`;

const MenuItemText = styled(Title)`
  margin-top: 5px;
`;

const items: MenuProps['items'] = [
  {
    label: 'Головна',
    key: ROUTES.ROOT
  },
  {
    label: 'Мої відпустки',
    key: ROUTES.LEAVE_APPLICATIONS
  }
];

interface IMenuItemProps {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const MenuItem: React.FC<IMenuItemProps> = ({ isActive, children, onClick }) => {
  return (
    <StyledMenuItem $isActive={isActive} onClick={onClick}>
      <MenuItemText level={5} type={isActive ? undefined : 'secondary'}>
        {children}
      </MenuItemText>
    </StyledMenuItem>
  );
}

export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userProfile = useSelector(profileSelector);
  const screen = useBreakpoint();
  const isMobile = !screen.lg;

  const [openConfirmLogoutModal, closeConfirmLogoutModal] = useModal(
    ModalType.ConfirmLogout
  );

  const handleOnClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  const handleMenuItemOnClick = (route: ValueOf<typeof ROUTES>) => {
    navigate(route);
    handleDrawerClose();
  };

  const handleLogoutClick = useCallback(() => {
    const payload = {
      redirectTo: navigate
    };

    closeConfirmLogoutModal()
    dispatch(logout(payload));
  }, [dispatch, navigate, closeConfirmLogoutModal]);

  const requestLogout = useCallback(() => {
    openConfirmLogoutModal({ onSubmit: handleLogoutClick, onClose: closeConfirmLogoutModal });
  }, [openConfirmLogoutModal, closeConfirmLogoutModal, handleLogoutClick]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  return (
    <>
    <Stub $isMobile={isMobile} />
    <StyledRow data-testid='Header' $isMobile={isMobile} justify={START} align={MIDDLE}>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Container>
          <LeftSideWrapper>
            <StyledMenuOutlined $isMobile={isMobile} onClick={handleOpenDrawer}/>
            <Logo src={logo} />
            <MenuContainer $isMobile={isMobile}>
              <StyledMenu
                triggerSubMenuAction='click'
                onClick={handleOnClick}
                selectedKeys={[location.pathname]}
                mode="horizontal"
                items={items}
              />
            </MenuContainer>
          </LeftSideWrapper>
          <RightSideWrapper>
            <Space size='middle'>
              <NameContainer $isMobile={!screen.xs}>
                <div>
                  <Text strong>{userProfile?.pib}</Text>
                </div>
                <div>
                  <Text type="secondary">{userProfile?.company}</Text>
                </div>
              </NameContainer>
              <img src={avatar} />
              <StyledButton data-testid='logout' onClick={requestLogout} size='large' icon={<ExitIcon />}>
                {isMobile ? '' : 'Вихід'}
              </StyledButton>
            </Space>
          </RightSideWrapper>
        </Container>
      </Col>
    </StyledRow>
    <Drawer
        placement='left'
        width={screen.xs ? '100%' : '50%'}
        onClose={handleDrawerClose}
        open={open}
      >
        <div>
          <MenuItem
            isActive={location.pathname === ROUTES.ROOT}
            onClick={() => { handleMenuItemOnClick(ROUTES.ROOT) }}
          >
            Головна
          </MenuItem>
        </div>
        <div>
          <MenuItem
            isActive={location.pathname === ROUTES.LEAVE_APPLICATIONS}
            onClick={() => { handleMenuItemOnClick(ROUTES.LEAVE_APPLICATIONS) }}
          >
            Відпустки
          </MenuItem>
        </div>
      </Drawer>
    </>
  );
};
