import {
  type ILeaveApplicationFormData,
  type ICreateLeavePayload
} from 'src/types/leaveApplications/types';
import { formatDateWithoutTimezone } from '../date';

export const dumpCreatePartTimeJobLeaveApplications = (
  formData: ILeaveApplicationFormData,
  parentVacationId: number
): ICreateLeavePayload[] => {
  const selectedPartTimeJobs = formData.partTimeJobs.items.filter((job) => job.isActive && !job.disabled);

  const formattedPartTimeJobs = selectedPartTimeJobs?.map((job) => {
    return {
      originatorPrincipal: job.principalName_FK,
      positionIdentifier: job.positionIdentifier,
      dateFrom: formatDateWithoutTimezone(job.dateFrom),
      dateTo: formatDateWithoutTimezone(job.dateTo),
      addToNextSalary: formData.info.addToNextSalary,
      parentRequestID_FK: parentVacationId,
      companyIdentifier: job.companyIdentifier,
      vacationIdentifier: job.vacationIdentifier,
      admManager: job.admManagerPrincipalName ?? '',
      fnManager: job.fnManagerPrincipalName ?? '',
      comment: formData.info.comment ?? ''
    };
  });

  return formattedPartTimeJobs;
};
