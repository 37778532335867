import { parseServerError } from 'src/constants/error';
import { openModal } from './modals';
import { ModalType } from './modals/types';

export const handleException = (err: any, dispatch?: any) => {
  const { title, message } = parseServerError(err);

  dispatch(
    openModal({
      type: ModalType.ErrorModal,
      componentProps: {
        title,
        message
      }
    })
  );
};
