import React from 'react';
import styled from 'styled-components';

import { Button, Typography } from 'antd';
import { LeaveApplicationStatusData, type LeaveApplicationStatus } from 'src/constants/status';
import { LeaveApplicationStatusColors } from 'src/features/theme/defaultTheme';

const CustomButton = styled(Button)<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  border: none;
  outline: ${(props) => props.theme.colors.borderColor} solid 2px;
  padding: 8px 16px;
  height: 38px;

  &:hover {
    outline-color: ${(props) => props.theme.colors.borderHover} !important;
  }

  ${(props) =>
    props.$isActive && {
      backgroundColor: props.theme.colors.bgChecked,
      outlineColor: props.theme.colors.bgChecked
    }}
`;

const ButtonCounter = styled.div`
  display: flex;
  width: 24px;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 100px;
  background: ${(props) => props.theme.antd.colorError};
`;

const ButtonCounterText = styled.span`
  color: ${(props) => props.theme.colors.disabledText};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

interface IProps {
  status: LeaveApplicationStatus;
  statusFilter?: LeaveApplicationStatus | null;
  itemsNumber?: number;
  onChange: (value: LeaveApplicationStatus) => void;
}

export const FilterButton: React.FC<IProps> = ({ status, statusFilter, itemsNumber, onChange }) => {
  const { icon: Icon, text } = LeaveApplicationStatusData[status];
  const colors = LeaveApplicationStatusColors[status];

  const handleChange = () => { onChange(status); }

  return (
    <CustomButton
      $isActive={statusFilter === status}
      onClick={handleChange}
      icon={<Icon style={{ fontSize: '24px', color: colors.textColor }} />}
    >
      <Typography.Text style={{ fontSize: '16px' }}>{text}</Typography.Text>
      {statusFilter === status && (
        <ButtonCounter>
          <ButtonCounterText>{itemsNumber}</ButtonCounterText>
        </ButtonCounter>
      )}
    </CustomButton>
  );
};
