import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Col, Row } from 'src/components';
import { CENTER, MIDDLE } from 'src/constants/components/row';

import { Flex, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/constants/routes';
import {
  setFilters,
  getLeaveApplicationsList,
  leaveApplicationFiltersSelector,
  leaveApplicationItemsIsLoadingSelector,
  leaveApplicationItemsSelector,
  leaveApplicationPaginationSelector,
  setCurrentLeaveApplication,
  setPagination,
  leaveApplicationAllItemsSelector,
  restFilters,
  leaveApplicationInReworkStatusSelector,
  pendingApplicationIdSelector
} from 'src/store/leaveApplications';
import { type Filters, type ILeaveApplicationEntity } from 'src/types/leaveApplications/types';

import { useAppDispatch } from 'src/hooks';
import {
  ApplicationsWarning,
  LeaveApplicationFilters,
  LeaveApplicationHeader,
  LeaveApplicationTable,
  LeaveApplicationsPlaceholder
} from 'src/features/leaveApplications';
import { generatePathWithParams } from 'src/utils/routeUtils';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.antd.boxShadow};
  border-radius: 20px;
  background: ${(props) => props.theme.antd.colorBgBase};
  padding: 24px;
  margin-bottom: 24px;
  overflow-y: auto;
  border-radius: 20px;
  min-height: calc(100vh - 144px);
`;

export const LeaveApplications: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const items = useSelector(leaveApplicationItemsSelector);
  const allItems = useSelector(leaveApplicationAllItemsSelector);
  const isLoading = useSelector(leaveApplicationItemsIsLoadingSelector);
  const pagination = useSelector(leaveApplicationPaginationSelector);
  const filters = useSelector(leaveApplicationFiltersSelector);
  const leaveApplicationsInReworkStatus = useSelector(leaveApplicationInReworkStatusSelector);
  const pendingApplicationId = useSelector(pendingApplicationIdSelector);

  useEffect(() => {
    dispatch(getLeaveApplicationsList());

    return () => {
      const pageNumber = 1;

      dispatch(restFilters());
      dispatch(setPagination(pageNumber));
    }
  }, [dispatch]);

  const handleCreateLeaveApplication = () => {
    navigate(ROUTES.CREATE_LEAVE_APPLICATIONS);
  };

  const handleEditLeaveApplication = useCallback(
    (record: ILeaveApplicationEntity) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      navigate(generatePathWithParams(ROUTES.EDIT_LEAVE_APPLICATIONS, { id: record.vacationId }));
    },
    [navigate]
  );

  const handleOnRowClick = useCallback(
    (record: ILeaveApplicationEntity) => (e: React.MouseEvent<HTMLElement>) => {
      dispatch(setCurrentLeaveApplication(record));
      navigate(generatePathWithParams(ROUTES.VIEW_LEAVE_APPLICATIONS, { id: record.vacationId }));
    },
    [navigate, dispatch]
  );

  const handleOnPaginationChange = useCallback(({ pageNumber }: { pageNumber: number }) => {
    dispatch(setPagination(pageNumber));
  }, [dispatch]);

  const handleOnFilterChange = useCallback((filters: Filters) => {
    dispatch(setFilters(filters));
  }, [dispatch]);

  const handleResetFilters = useCallback(() => {
    dispatch(restFilters());
  }, [dispatch]);

  function renderTable() {
    if (isLoading) {
      return (
        <Flex align="center" justify="center" flex={1}>
          <Spin size="large" />
        </Flex>
      );
    }

    if (!allItems.length) {
      return <LeaveApplicationsPlaceholder onCreate={handleCreateLeaveApplication} />;
    }

    return (
      <Flex vertical gap={16}>
        <LeaveApplicationFilters
          itemsCount={items.length}
          onFilterChange={handleOnFilterChange}
          onResetFilters={handleResetFilters}
          filters={filters}
        />
        {leaveApplicationsInReworkStatus.length
          ? <ApplicationsWarning>
              Звертаємо Вашу увагу на необхідні зміни у Вашій заявці на відпустку зі статусом “На
              доопрацювання”. Будь ласка, перевірте та внесіть корективи
            </ApplicationsWarning>
          : null}
        <LeaveApplicationTable
          data={items}
          pagination={pagination}
          pendingApplicationId={pendingApplicationId}
          onEdit={handleEditLeaveApplication}
          onRowClick={handleOnRowClick}
          onPaginationChange={handleOnPaginationChange}
        />
      </Flex>
    );
  }

  return (
    <Row data-testid="LeaveApplications" justify={CENTER} align={MIDDLE}>
      <Col xs={24} lg={24}>
        <Content>
          <LeaveApplicationHeader
            onCreate={handleCreateLeaveApplication}
          />
          {renderTable()}
        </Content>
      </Col>
    </Row>
  );
};
