import { handleRequest } from '../utils/request';
import Base from './Base';

interface IPayload {
  phone?: string;
  code?: string;
}

class Sessions extends Base {
  requestCode = async (payload: IPayload) => {
    return await handleRequest(this.apiClient.post('sessions/request-code', payload));
  };

  confirmCode = async (payload: IPayload) => {
    return await handleRequest(this.apiClient.post('sessions/confirm-code', payload));
  };
}

export default Sessions;
