// justify
export const START = 'start';
export const END = 'end';
export const CENTER = 'center';
export const SPACE_AROUND = 'space-around';
export const SPACE_BETWEEN = 'space-between';
export const SPACE_EVENTLY = 'start';
export const FLEX_END = 'flex-end';
export const FLEX_START = 'flex-start';

export type JustifyType = 'start' | 'end' | 'center' | 'center' | 'space-around' | 'space-between';

// align
export const TOP = 'top';
export const MIDDLE = 'middle';
export const BOTTOM = 'bottom';

export type AlignType = 'top' | 'middle' | 'bottom';
