import React from 'react';
import styled from 'styled-components';
import type { Moment } from 'moment';
import 'moment/locale/uk';

import { type RangeValue } from 'src/types/common';
import { DatePicker } from 'src/components/DatePicker/DatePicker';
import './FilterRangePicker.scss';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';

const Container = styled.div`
  display: flex;
  width: 300px;
  gap: 10px;
`;

interface IProps {
  value: RangeValue<Moment> | null;
  onChange: (name: string, value: Moment | null) => void;
}

export const FilterRangePicker: React.FC<IProps> = ({ value, onChange }) => {
  const handleChangeDateField: (name: string) => PickerProps<Moment>['onChange'] = (name) => (date) => {
    onChange(name, date);
  };

  return (
    <Container data-testid="DateRangeLeaveFilter">
      <DatePicker
        testId='dateFrom'
        placeholder="Початкова дата"
        value={value?.[0]}
        onChange={handleChangeDateField('dateFrom')}
        format='DD.MM.YYYY'
        rounded
        inputReadOnly
      />
      <DatePicker
        testId='dateTo'
        placeholder="Кінецева дата"
        value={value?.[1]}
        onChange={handleChangeDateField('dateTo')}
        format='DD.MM.YYYY'
        rounded
        inputReadOnly
      />
    </Container>
  );
};
