import { Button, Grid } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { useBreakpoint } = Grid;

const Section = styled.section`
  padding: 0 28px;
`;

const Footer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.$isMobile ? 'column' : 'row')};
  gap: 10px;
`;

const FooterButtonsWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  gap: 10px;
  width: ${(props) => (props.$isMobile ? '100%' : 'auto')};
  flex-direction: ${(props) => (props.$isMobile ? 'column' : 'row')};
`;

interface IProps {
  okText?: string;
  cancelText?: string;
  prevText?: string;
  onOk?: () => void;
  onPrev?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const LeaveApplicationFooter: React.FC<IProps> = ({
  okText,
  cancelText,
  prevText,
  onOk,
  onPrev,
  onCancel,
  disabled
}) => {
  const screen = useBreakpoint();

  return (
    <Section style={{ marginTop: '14px' }}>
      <Footer $isMobile={!screen.md}>
        <FooterButtonsWrapper $isMobile={!screen.md}>
          {onCancel &&
            <Button size="large" onClick={onCancel} disabled={disabled}>
              {cancelText}
            </Button>
          }
        </FooterButtonsWrapper>
        <FooterButtonsWrapper $isMobile={!screen.md}>
          {onPrev &&
            <Button
              data-testid='prevButton'
              size="large"
              block
              onClick={onPrev}
              disabled={disabled}
            >
              {prevText}
            </Button>
          }
          {onOk &&
            <Button
              data-testid='nextButton'
              size="large"
              type="primary"
              block
              onClick={onOk}
              disabled={disabled}
              loading={disabled}
            >
              {okText}
            </Button>
          }
        </FooterButtonsWrapper>
      </Footer>
    </Section>
  );
};
