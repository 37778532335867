import { decode } from 'base64-arraybuffer';
import { PDFDocument } from 'pdf-lib';

export async function signPdfFile(
  fileAsBase64: string,
  signature: string,
  position: { x: number; y: number }
) {
  const existingPdfBytes = decode(fileAsBase64)

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const pngImageBytes = await fetch(signature).then(async (res) => await res.arrayBuffer())
  const pngImage = await pdfDoc.embedPng(pngImageBytes)

  firstPage.drawImage(pngImage, {
    x: position.x,
    y: position.y,
    width: 100,
    height: 50
  })

  const fileUrl = await pdfDoc.saveAsBase64({ dataUri: true })

  return fileUrl
}
