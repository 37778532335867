import { notification } from 'antd';
import { useEffect } from 'react';

const NOTIFICATION_KEY = 'BROWSER_STATUS'

export const useBrowserStatus = () => {
  useEffect(() => {
    const handleShowNotification = () => {
      notification.error({
        key: NOTIFICATION_KEY,
        message: 'Ви не підключені до інтернету',
        duration: 0
      });
    };

    const handleCloseNotification = () => {
      notification.destroy(NOTIFICATION_KEY)
    };

    window.addEventListener('offline', handleShowNotification);
    window.addEventListener('online', handleCloseNotification);

    return () => {
      window.removeEventListener('offline', handleShowNotification);
      window.removeEventListener('online', handleCloseNotification);
    };
  }, []);
};
