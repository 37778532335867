import { type DefaultOptionType } from 'antd/es/select';
import { type ILeaveApplicationType } from 'src/types/leaveApplications/types';

export const dumpApplicationTypes = (data: ILeaveApplicationType[]): DefaultOptionType[] => {
  return data
    .map((item) => ({
      key: item.vacationIdentifier,
      label: item.vacationName,
      value: item.vacationIdentifier,
      daysLimit: item.daysLimit
    }));
};
