import React from 'react';
import { Row, type RowProps } from 'antd';

import { START, TOP, type JustifyType, type AlignType } from 'src/constants/components/row';

interface IProps {
  className?: string;
  align?: AlignType;
  justify?: JustifyType;
  children: React.ReactNode;
}

const CustomRow: React.FC<IProps | (RowProps & Omit<IProps, ''>)> = (props) => {
  const { className = '', align = TOP, justify = START, children } = props;

  return (
    <Row {...props} className={className} align={align} justify={justify}>
      {children}
    </Row>
  );
};

export { CustomRow as Row };
