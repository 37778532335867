import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { formatCountdown } from 'src/utils/date';

interface IProps {
  countdownDuration: number;
  onComplete: () => void;
}

const Countdown: React.FC<IProps> = (props) => {
  const { countdownDuration, onComplete } = props;

  return (
    <CountdownCircleTimer
          isPlaying
          duration={countdownDuration}
          colors='#286AE4'
          strokeWidth={10}
          size={100}
          updateInterval={1}
          onComplete={onComplete}
        >
          {({ elapsedTime, color }) => {
            const countdown = formatCountdown(countdownDuration, elapsedTime);

            return (
              <span data-testid='countdown' style={{ color }}>
                {countdown}
              </span>
            )
          }}
      </CountdownCircleTimer>
  );
};

export default Countdown;
