import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

const StyledTag = styled(Tag)<{ $colorText: string }>`
  height: 24px;
  display: inline-flex;
  align-items: center;
  border-radius: 12px;

  span {
    color: ${props => props.$colorText}
  }
`;

interface IProps {
  statusText: string;
  icon: React.ReactElement;
  colors: { bgColor: string; textColor: string }
}

export const Status: React.FC<IProps> = ({ statusText, icon, colors }) => {
  return (
    <StyledTag
      bordered={false}
      icon={icon}
      color={colors.bgColor}
      $colorText={colors.textColor}>
        {statusText}
    </StyledTag>
  );
};
