import InputMask from 'inputmask';
import { MASKS_NAME } from 'src/constants/masks';

const defaultMaskOptions = {
  placeholder: '',
  rightAlign: false,
  showMaskOnHover: false
};

export const phoneMask = {
  ...defaultMaskOptions,
  mask: '(099) 999-99-99',
  placeholder: '(0XX) XXX-XX-XX',
  autoUnmask: true
};

export const codeMask = {
  ...defaultMaskOptions,
  mask: '99-99-99',
  placeholder: 'XX-XX-XX',
  autoUnmask: true
};

export const emailMask = {
  ...defaultMaskOptions,
  regex: '[^ЁёЭэЇїІіЄєА-я]*'
};

export const dateMask = {
  ...defaultMaskOptions,
  placeholder: 'дд.мм.гггг',
  mask: '99.99.9999'
};

export const confirmationMask = {
  ...defaultMaskOptions,
  placeholder: 'xxx-xxx',
  mask: '999-999'
};

const decimalMask = {
  ...defaultMaskOptions,
  alias: 'decimal',
  mask: '9[.9]',
  placeholder: '_'
};

const numberMask = {
  ...defaultMaskOptions,
  regex: '\\d*'
};

const edrpouMask = {
  ...defaultMaskOptions,
  mask: '99999999'
};

const floatMask = {
  ...defaultMaskOptions,
  regex: '^[0-9\\.]*$'
};

export const masks = {
  [MASKS_NAME.PHONE]: phoneMask,
  [MASKS_NAME.CODE]: codeMask,
  [MASKS_NAME.EMAIL]: emailMask,
  [MASKS_NAME.CONFIRMATION]: confirmationMask,
  [MASKS_NAME.DATE]: dateMask,
  [MASKS_NAME.DECIMAL]: decimalMask,
  [MASKS_NAME.NUMBER]: numberMask,
  [MASKS_NAME.EDRPOU]: edrpouMask,
  [MASKS_NAME.FLOAT]: floatMask
};

export const maskTypes = Object.keys(masks);

export type maskType =
  | 'phone'
  | 'code'
  | 'email'
  | 'confirmation'
  | 'date'
  | 'decimal'
  | 'number'
  | 'edrpou'
  | 'float';

export const applyMask = (maskType: maskType, target: string) => {
  if (maskTypes.includes(maskType)) {
    InputMask(masks[maskType]).mask(target);
  } else {
    console.warn('WRONG MASK TYPE: ', maskType);
  }
};
