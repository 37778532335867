import type { ILeaveApplicationFormData, IValidateLeavePayload } from 'src/types/leaveApplications/types';
import { formatDateWithoutTimezone } from '../date';

export const dumpValidateLeaveApplication = (data: ILeaveApplicationFormData): IValidateLeavePayload => {
  return {
    vacationId: data.info.vacationId,
    positionIdentifier: data.info.positionIdentifier,
    dateFrom: formatDateWithoutTimezone(data.info.dateFrom),
    dateTo: formatDateWithoutTimezone(data.info.dateTo),
    companyIdentifier: data.info.company.value as string,
    vacationIdentifier: data.info.vacationType.value as string,
    daysLimit: data.info.daysLimit,
    admManager: data.info.admManager.value as string ?? '',
    fnManager: data.info.fnManager.value as string ?? ''
  };
};
