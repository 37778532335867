import FormData from 'form-data';

export const createFormData = (data: any): FormData => {
  const formData = new FormData();

  createFormDataKey(formData, data);

  return formData;
};

const createFormDataKey = (formData: FormData, data: any, parentKey?: string): void => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      createFormDataKey(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    formData.append(parentKey ?? '', data);
  }
};
