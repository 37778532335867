import { type UploadFile } from 'antd';
import { type RcFile } from 'antd/es/upload';
import { getBase64Src } from '../image';
import { type IDocumentUploadPayload } from 'src/types/leaveApplications/types';

interface IDumpUploadLeaveApplicationDocument {
  file: UploadFile;
  vacationId: number;
}

export const dumpUploadLeaveApplicationDocument = async ({
  file,
  vacationId
}: IDumpUploadLeaveApplicationDocument): Promise<IDocumentUploadPayload | null> => {
  const base64 = await getBase64Src(file.originFileObj as RcFile);
  if (!base64) return null;

  const formattedBase64 = base64.split(',')[1];

  return {
    vacationId,
    filePath: file.name,
    title: file.name,
    content: formattedBase64,
    comment: ''
  };
};
