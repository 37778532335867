import React from 'react';
import styled from 'styled-components';

import { Button, Grid, Table, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { type TableRowSelection } from 'antd/es/table/interface';
import { type SignLeaveApplicationRecord } from 'src/types/leaveApplications/types';
import { formatDate } from 'src/utils/date';
import { usePreviewDocument } from 'src/features/leaveApplication/hooks/usePreviewDocument';
import { StackTable } from 'src/components/StackTable/StackTable';

const { useBreakpoint } = Grid;

const TableContainer = styled.div<{ isDesktop: boolean }>`
  overflow-x: auto;
  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: 4px;
  }
  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    padding-right: 4px;
  }
  .ant-table-tbody > tr:last-child > td {
    border: none;
  }

  .ant-table-row > td {
    font-size: ${(props) => (props.isDesktop ? '16px' : '14px')};
  }

  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0px 8px;
  color: ${(props) => props.theme.colors.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

interface IProps {
  rowSelection?: TableRowSelection<SignLeaveApplicationRecord>;
  data: SignLeaveApplicationRecord[];
}

export const SignLeaveApplicationTable: React.FC<IProps> = ({ rowSelection, data }) => {
  const screen = useBreakpoint();
  const isMobile = !!screen.xs;
  const { previewOpen, previewImage, previewTitle, handlePreview, handleCancel } = usePreviewDocument();

  const columns: ColumnsType<SignLeaveApplicationRecord> = [
    {
      title: 'Вид відпустки',
      key: 'vacationName',
      align: 'left',
      dataIndex: 'vacationName'
    },
    {
      title: 'Посада',
      key: 'positionName',
      align: 'left',
      dataIndex: 'positionName'
    },
    {
      title: 'Початок періоду',
      key: 'dateFrom',
      dataIndex: 'dateFrom',
      render: (_, record) => <span>{formatDate(record.dateFrom)}</span>
    },
    {
      title: 'Кінець періоду',
      key: 'dateTo',
      dataIndex: 'dateTo',
      render: (_, record) => <span>{formatDate(record.dateTo)}</span>
    },
    {
      title: 'Кількість днів',
      key: 'usedDays',
      align: 'right',
      dataIndex: 'usedDays'
    },
    {
      title: 'Документ',
      key: 'document',
      align: 'right',
      dataIndex: 'document',
      render: (_, record) => {
        return (
          <ButtonContainer>
            <StyledButton type='text' onClick={() => { handlePreview(record.document) }}>
              Перегляд
            </StyledButton>
          </ButtonContainer>
        );
      }
    }
  ];

  return (
    <TableContainer isDesktop={!!screen.md}>
      {!isMobile ? (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey={(record: SignLeaveApplicationRecord) => record.document?.uid}
        />
      ) : (
        <StackTable columns={columns} dataSource={data} />
      )}
      <Modal data-testid='doc-preview' open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} zIndex={10000}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </TableContainer>
  );
};
