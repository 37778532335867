import React from 'react';
import { Modal, Typography } from 'antd';
import { type LeaveApplicationIsSignedModalProps } from './LeaveApplicationIsSignedModalProps';
import { ReactComponent as CheckedIcon } from 'src/assets/images/checked.svg';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 48px 24px;
  text-align: center;
`;

export const LeaveApplicationIsSignedModal = ({
  title,
  text,
  onSubmit,
  onClose
}: LeaveApplicationIsSignedModalProps) => {
  return (
    <Modal
      open
      data-testid="LeaveApplicationIsSignedModal"
      title={title}
      onOk={onSubmit}
      onCancel={onClose}
      okText="Перейти до Мої відпустки"
      width={800}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Content>
        <CheckedIcon />
        <Typography.Text style={{ fontSize: '16px' }}>
          {text}
        </Typography.Text>
      </Content>
    </Modal>
  );
};
