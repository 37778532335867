import { type IUpdatedDocumentPayload } from 'src/types/leaveApplications/types';

export interface IDumpSignedLeaveApplicationDocument {
  document: string;
  vacationId: number;
  filePath: string;
}

export const dumpSignedApplicationDocument = ({
  document,
  vacationId,
  filePath
}: IDumpSignedLeaveApplicationDocument): IUpdatedDocumentPayload => {
  const formattedBase64 = document.split(',')[1];

  return {
    vacationId,
    filePath,
    fileContent: formattedBase64
  };
};
