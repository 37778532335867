import { dumpListUsers } from 'src/utils/dumps/dumpListUsers';
import { handleRequest } from '../utils/request';
import Base from './Base';
import { type DefaultOptionType } from 'antd/es/select';

export interface IListUsersPayload {
  search?: string;
  PageNumber?: string;
  PageSize?: string
}

class Users extends Base {
  get = async (principalName: string) => {
    return await handleRequest(this.apiClient.get(`users/show/${encodeURIComponent(encodeURIComponent(principalName))}`));
  };

  getList = async (payload: IListUsersPayload): Promise<DefaultOptionType[]> => {
    const { data } = await handleRequest(this.apiClient.get('users/list', payload));

    return dumpListUsers(data)
  };
}

export default Users;
