import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Grid } from 'antd';

import { type AppDispatch } from 'src/store/rootStore';
import { LoginForm } from 'src/features/ui';
import { Row, Col } from 'src/components';

import {
  sessionErrorSelector,
  setSessionErrors,
  requestCode,
  isLoadingRequestCodeSessionSelector,
  isLoadingConfirmCodeSessionSelector,
  sessionViewSelector,
  confirmCode,
  setSessionView,
  clearSessionsState
} from 'src/store/sessions';
import loginBg1 from 'src/assets/images/login-backgrounds/bg-1.png';
import loginBg2 from 'src/assets/images/login-backgrounds/bg-2.png';
import loginBg3 from 'src/assets/images/login-backgrounds/bg-3.png';
import loginBg4 from 'src/assets/images/login-backgrounds/bg-4.png';

import logo from 'src/assets/images/mhp-logo.png';

import { CENTER, MIDDLE } from 'src/constants/components/row';
import { preloadImages } from 'src/utils/image';
import { ConfirmCodeForm, type IConfirmCodeData } from 'src/features/ui/Forms/ConfirmCodeForm/ConfirmCodeForm';
import { type ILoginData } from 'src/features/ui/Forms/LoginForm/LoginForm';
import { TimerForm } from 'src/features/ui/Forms/TimerForm/TimerForm';
import { SessionView } from 'src/store/sessions/types';

const { useBreakpoint } = Grid;

const Background = styled.div`
  height: 100vh;
  background-image: url(${loginBg1});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
  background-color: #fff;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 34px;
  background: #fff;
`;

const FormWrapper = styled.div`
  max-width: 369px;
`;

const Logo = styled.img`
  height: 28px;
  width: 95px;
  margin-bottom: 87px;
`;

const BACKGROUNDS = [loginBg1, loginBg2, loginBg3, loginBg4];

function setBackground(target: HTMLDivElement | null) {
  const images = preloadImages(BACKGROUNDS);
  const totalCount = images.length;
  let currentIndex = 0;

  return setInterval(function() {
    if (currentIndex >= totalCount) currentIndex = 0;

    if (target) {
      target.style.backgroundImage = `url(${images[currentIndex].currentSrc})`;
      currentIndex++;
    }
  }, 5000);
}

export const Login: React.FC = () => {
  const bgRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const screen = useBreakpoint();
  const error = useSelector(sessionErrorSelector);
  const sessionView = useSelector(sessionViewSelector);
  const isLoadingRequestCode = useSelector(isLoadingRequestCodeSessionSelector);
  const isLoadingConfirmCode = useSelector(isLoadingConfirmCodeSessionSelector);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;

    if (screen.sm ?? screen.md ?? screen.lg ?? screen.xl ?? screen.xxl) {
      intervalId = setBackground(bgRef.current);
    }

    return () => {
      dispatch(setSessionErrors(null));
      clearInterval(intervalId);
    };
  }, [dispatch, screen.sm, screen.md, screen.lg, screen.xl, screen.xxl]);

  const handleRequestCode = useCallback((data: ILoginData) => {
    const payload = {
      credentials: data
    };

    dispatch(requestCode(payload));
  }, [dispatch]);

  const handleConfirmCodeSubmit = useCallback((data: IConfirmCodeData) => {
    const payload = {
      credentials: data
    };

    dispatch(confirmCode(payload));
  }, [dispatch]);

  const handleLoginRedirect = useCallback(() => {
    dispatch(setSessionView(SessionView.login));
    dispatch(clearSessionsState());
  }, [dispatch]);

  return (
    <Row justify={CENTER} align={MIDDLE}>
      <Col xs={0} sm={0} md={12}>
        <Background ref={bgRef}/>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <FormContainer>
          <Logo src={logo} />
          <FormWrapper>
            {sessionView === SessionView.login && <LoginForm onSubmit={handleRequestCode} error={error} loading={isLoadingRequestCode} />}
            {sessionView === SessionView.confirmCode && <ConfirmCodeForm onSubmit={handleConfirmCodeSubmit} error={error} loading={isLoadingConfirmCode} />}
            {sessionView === SessionView.timer && <TimerForm onClick={handleLoginRedirect} />}
          </FormWrapper>
        </FormContainer>
      </Col>
    </Row>
  );
};
