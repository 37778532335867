import type { ICreateLeavePayload, ILeaveApplicationFormData } from 'src/types/leaveApplications/types';
import { formatDateWithoutTimezone } from '../date';

export const dumpCreateLeaveApplication = (data: ILeaveApplicationFormData): ICreateLeavePayload => {
  return {
    originatorPrincipal: data.info.principalName,
    positionIdentifier: data.info.positionIdentifier,
    dateFrom: formatDateWithoutTimezone(data.info.dateFrom),
    dateTo: formatDateWithoutTimezone(data.info.dateTo),
    addToNextSalary: data.info.addToNextSalary,
    parentRequestID_FK: null,
    companyIdentifier: data.info.company.value as string,
    vacationIdentifier: data.info.vacationType.value as string,
    admManager: data.info.admManager.value as string ?? '',
    fnManager: data.info.fnManager.value as string ?? '',
    comment: data.info.comment ?? ''
  };
};
