import { Button } from 'antd';
import React, { forwardRef } from 'react';
import ReactSignaturePad from 'react-signature-pad-wrapper';
import { ReactComponent as EraseIcon } from 'src/assets/images/erase.svg';
import styled from 'styled-components';

const SignaturePadContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.secondaryText};
`;

const ClearButton = styled(Button)`
  margin: 4px;
  padding: 0px 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
`;

interface IProps {
  onClear: () => void;
}

export const SignaturePad = forwardRef<ReactSignaturePad, IProps>(({ onClear }, ref) => {
  return (
    <SignaturePadContainer>
      <div>
        <ClearButton type="text" onClick={onClear}>
          <EraseIcon />
          Очистити
        </ClearButton>
      </div>
      <ReactSignaturePad ref={ref} redrawOnResize options={{ maxWidth: 6, backgroundColor: 'rgb(255,255,255)' }} />
    </SignaturePadContainer>
  );
});

SignaturePad.displayName = 'SignaturePad'
