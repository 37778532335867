import { handleRequest } from '../utils/request';
import Base from './Base';

interface IReset {
  password?: string;
}

interface ISignup {
  password?: string;
  confirmPassword?: string;
}

export interface ISessionsList {
  browser: string;
  createdAt: string;
  geoIp: object;
  id: string;
  ip: string;
  os: string;
  platform: string;
  version: string;
}

class Actions extends Base {
  resetPassword = async (payload: IReset, token: string) => {
    return await handleRequest(this.apiClient.post(`actions/reset-password/${token}`, payload));
  };

  activateUser = async (payload: ISignup, token: string) => {
    return await handleRequest(this.apiClient.post(`actions/activate-user/${token}`, payload));
  };

  checkAction = async (id: string) => {
    return await handleRequest(this.apiClient.get(`actions/${id}`));
  };
}

export default Actions;
