import { handleRequest } from 'src/utils/request';
import Base from './Base';

class LeaveApplications extends Base {
  get = async (vacationId: number) => {
    return await handleRequest(this.apiClient.get(`vacations/show/${vacationId}`));
  };

  getList = async <R>(): Promise<{ data: R }> => {
    return await handleRequest(this.apiClient.get('vacations/list'));
  };

  create = async <P>(payload: P) => {
    return await handleRequest(this.apiClient.post('vacations/create', payload));
  };

  submitProcess = async <P>(payload: P) => {
    return await handleRequest(this.apiClient.post('vacations/submit-process', payload));
  };

  submitAction = async <P>(payload: P) => {
    return await handleRequest(this.apiClient.post('vacations/submit-action', payload));
  };

  update = async <P>(payload: P) => {
    return await handleRequest(this.apiClient.patch('vacations/update', payload));
  };

  delete = async (parentVacationId: number) => {
    return await handleRequest(this.apiClient.delete(`vacations/delete/${parentVacationId}`));
  };

  validateDates = async <P>(payload: P) => {
    return await handleRequest(this.apiClient.post('vacations/validate', payload));
  };
}

export default LeaveApplications;
