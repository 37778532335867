class StorageClass {
  private readonly storage: Storage;
  private readonly name: string;

  constructor(storage: Storage, name: string) {
    this.storage = storage;
    this.name = name;
  }

  save(key: string, data: any) {
    try {
      const dataJSON = JSON.stringify(data);

      this.storage.setItem(key, dataJSON);
    } catch (err) {
      console.log(`${key} saving to ${this.name} error: `, err);
    }
  }

  get(key: string) {
    try {
      const data = this.storage.getItem(key);

      return data ? JSON.parse(data) : data;
    } catch (err) {
      console.log(`${key} getting from ${this.name} error: `, err);
    }
  }

  clear() {
    try {
      this.storage.clear();
    } catch (err) {
      console.log(`Clearing ${this.name} error: `, err);
    }
  }

  remove(key: string) {
    try {
      this.storage.removeItem(key);
    } catch (err) {
      console.log(`${key} removing from ${this.name} error: `, err);
    }
  }
}

export const localStorage = new StorageClass(window.localStorage, 'localStorage');
export const sessionStorage = new StorageClass(window.sessionStorage, 'sessionStorage');
