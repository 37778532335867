interface IConfig {
  apiUrl: string;
  apiPrefix: string;
}

declare global {
  interface Window {
    APP_CONFIG: IConfig;
  }
}

export default window.APP_CONFIG;
