import React from 'react';
import { Modal, Typography } from 'antd';
import { type ConfirmRemoveFileModalProps } from './ConfirmRemoveFileModalProps';
import { ReactComponent as ExclamationIcon } from 'src/assets/images/exclamation.svg';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 24px;
  text-align: center;
`;

export const ConfirmRemoveFileModal = ({ onClose, onSubmit }: ConfirmRemoveFileModalProps) => {
  return (
    <Modal
      data-testid="ConfirmRemoveFile"
      open
      title='Видалення документу'
      onOk={onSubmit}
      onCancel={onClose}
      cancelText="Ні, залишити"
      okText="Так, видалити"
    >
      <Content>
        <ExclamationIcon />
        <Typography.Text style={{ fontSize: '16px' }}>
          Ви впевнені, що хочете видалити документ?
        </Typography.Text>
      </Content>
    </Modal>
  );
};
