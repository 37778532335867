import { Tabs as AntTabs, type TabsProps } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav {
    padding: 0 28px;
  }

  .ant-tabs-nav:before {
    border-bottom: none;
  }
`;

interface Props {
  tabs: TabsProps['items'];
  defaultKey: string;
}

export const LeaveApplicationTabs: React.FC<Props> = ({ tabs, defaultKey }) => {
  const [currentTab, setTab] = useState(defaultKey);

  const handleChangeTab = (key: string) => {
    setTab(key);
  };

  return (
    <StyledTabs defaultActiveKey={currentTab} items={tabs} onChange={handleChangeTab} />
  );
};
