/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Flex, Grid, Spin, Switch, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { StackTable } from 'src/components/StackTable/StackTable';
import type {
  ILeaveApplicationFormData,
  ILeaveApplicationEntity,
  IPartTimeJobEntityExtended
} from 'src/types/leaveApplications/types';
import { formatDate } from 'src/utils/date';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const StyledTable = styled(Table)<any>`
  .ant-table {
    background: transparent;
  }

  .ant-table-cell {
    padding: 14px !important;
    background: transparent !important;
  }

  .ant-table-row > td {
    font-size: ${(props) => (props.screen.xl ? '16px' : '14px')};
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Section = styled.section`
  padding: 0 28px;
`;

const Text: React.FC<{ children: React.ReactNode; disabled?: boolean }> = ({
  children,
  disabled
}) => {
  const screen = useBreakpoint();
  const isMobile = !screen.xl;

  return (
    <Typography.Paragraph
      type={disabled ? 'secondary' : undefined}
      style={{ textAlign: isMobile ? 'right' : undefined }}
    >
      {children}
    </Typography.Paragraph>
  );
};

interface IProps {
  data: ILeaveApplicationFormData;
  isLoading: boolean;
  partTimeJobsIsLoading: boolean;
  existentPartTimeJobsLeaveApplications?: ILeaveApplicationEntity[];
  onChange: (name: string, value: unknown) => void;
  disabled?: boolean;
}

type IPartTimeJobsTableEntity = IPartTimeJobEntityExtended & {
  key: number;
  usedDays: number;
  dateRange: string;
  vacationType: string;
};

export const PartTimeJobForm: React.FC<IProps> = ({
  data,
  isLoading,
  partTimeJobsIsLoading,
  existentPartTimeJobsLeaveApplications,
  onChange,
  disabled
}) => {
  const screen = useBreakpoint();
  const isDesktop = !!screen.xl;

  const toggleActivePartTimeJob = useCallback(
    (id: number) => (checked: boolean) => {
      const newItems = data.partTimeJobs?.items.map((item) => {
        if (id === item.id) {
          return { ...item, isActive: checked };
        }

        return item;
      });

      onChange('items', newItems);
    },
    [data.partTimeJobs?.items, onChange]
  );

  const columns: ColumnsType<IPartTimeJobsTableEntity> = useMemo(
    () => [
      {
        title: 'Дії',
        key: 'action',
        dataIndex: 'action',
        render: (_, record) => {
          const isDisabled = isLoading || record.disabled || disabled;

          return (
            <Switch
              checked={record.isActive}
              disabled={!!isDisabled}
              onChange={toggleActivePartTimeJob(record.id)}
            />
          );
        }
      },
      {
        title: 'Організація',
        key: 'organization',
        dataIndex: 'organization',
        render: (_, record) => <Text disabled={!record.isActive}>{record.company}</Text>
      },
      {
        title: 'Посада',
        key: 'position',
        dataIndex: 'position',
        render: (_, record) => <Text disabled={!record.isActive}>{record.position}</Text>
      },
      {
        title: 'Вид відпустки',
        key: 'type',
        dataIndex: 'type',
        render: (_, record) => <Text disabled={!record.isActive}>{record.vacationType}</Text>
      },
      {
        title: 'К-ть днів',
        key: 'usedDays',
        dataIndex: 'usedDays',
        width: 100,
        render: (_, record) => <Text disabled={!record.isActive}>{record.usedDays}</Text>
      },
      {
        title: 'Дати',
        key: 'dateRange',
        dataIndex: 'dateRange',
        width: 150,
        render: (_, record) => <Text disabled={!record.isActive}>{record.dateRange}</Text>
      },
      {
        title: 'Функц. керівник',
        key: 'functionalManager',
        dataIndex: 'functionalManager',
        width: 150,
        render: (_, record) => <Text disabled={!record.isActive}>{record.fnManager}</Text>
      },
      {
        title: 'Адмін. керівник',
        key: 'adminManager',
        dataIndex: 'adminManager',
        width: 150,
        render: (_, record) => <Text disabled={!record.isActive}>{record.admManager}</Text>
      }
    ],
    [toggleActivePartTimeJob, existentPartTimeJobsLeaveApplications, isLoading, disabled]
  );

  const dataSource: IPartTimeJobsTableEntity[] = useMemo(() => {
    const partTimeJobs = data.partTimeJobs?.items;

    return partTimeJobs.map((item, index) => ({
      ...item,
      key: index,
      usedDays: item.usedDays,
      dateRange: `${formatDate(item.dateFrom)} - ${formatDate(item.dateTo)}`,
      vacationType: item.vacationType
    }));
  }, [data.partTimeJobs]);

  function renderTable() {
    if (partTimeJobsIsLoading) {
      return (
        <Flex align="center" justify="center" flex={1} style={{ minHeight: 300 }}>
          <Spin size="large" />
        </Flex>
      );
    }

    return (
      <TableContainer>
        {isDesktop ? (
          <StyledTable
            columns={columns}
            dataSource={dataSource}
            screen={screen}
            pagination={false}
          />
        ) : (
          <StackTable columns={columns} dataSource={dataSource} />
        )}
      </TableContainer>
    );
  }

  return (
    <Section>
      <Title level={5} style={{ margin: 0, marginBottom: 24 }}>
        Сумісництво
      </Title>
      {renderTable()}
    </Section>
  );
};
