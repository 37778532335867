import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'src/store/rootStore';

const getState = (state: RootState) => state.sessions;

export const sessionErrorSelector = createSelector(getState, (state) => state.error);
export const sessionViewSelector = createSelector(getState, (state) => state.sessionView);
export const isLoadingRequestCodeSessionSelector = createSelector(getState, (state) => state.isLoadingRequestCode);
export const isLoadingConfirmCodeSessionSelector = createSelector(getState, (state) => state.isLoadingConfirmCode);
export const userBlockedTimestampSelector = createSelector(getState, (state) => state.userBlockedTimestamp);
export const profileSelector = createSelector(getState, (state) => state.profile);
export const partTimeJobProfilesSelector = createSelector(getState, (state) => state.partTimeJobProfiles.items);
