import { getTextErrors } from './errors/messages';

interface IError {
  code: string;
  message?: string;
  fields: Record<string, string>;
}

interface IServerError {
  title: string;
  message: string;
  fields: Record<string, string>;
}

export const getMessageByCode = (code: string) => {
  return getTextErrors()[code] || 'Сталася помилка';
};

export const parseServerError = (errorObj: IError): IServerError => {
  const { fields, code } = errorObj;

  const title = 'Помилка';
  const message: string = errorObj?.message ? errorObj?.message : getMessageByCode(code);

  const currentError: Record<string, any> = {};

  if (fields) {
    Object.keys(fields).forEach((errorKey: string) => {
      const key: string = errorKey.substr(0, 4) === 'data' ? errorKey.slice(5) : errorKey;

      currentError[key] = fields[errorKey];
    });
  }

  return { title, message, fields: currentError };
};
