import { useCallback, useEffect, useState } from 'react';
import type ReactSignaturePad from 'react-signature-pad-wrapper';

export function useSignatureValidation() {
  const [isSignatureValid, setIsSignatureValid] = useState(false);
  const [signaturePad, setSignaturePad] = useState<ReactSignaturePad | null>();

  const signaturePadRef = useCallback((signaturePad: ReactSignaturePad | null) => {
    setSignaturePad(signaturePad);
  }, []);

  const onStrokeEnd = () => {
    setIsSignatureValid(true);
  }

  useEffect(() => {
    if (signaturePad) {
      signaturePad.instance.addEventListener('endStroke', onStrokeEnd);
    }

    return () => {
      signaturePad?.instance.removeEventListener('endStroke', onStrokeEnd);
    }
  }, [signaturePad]);

  return { signaturePadRef, signaturePad, isSignatureValid, setIsSignatureValid };
}
