export function updateOrAddElement<T extends { vacationId: number }>(items: T[], newItems: T[]): T[] {
  const itemsMap: Record<number, T> = {};

  items.forEach(item => {
    itemsMap[item.vacationId] = item;
  });

  newItems.forEach(newItem => {
    itemsMap[newItem.vacationId] = newItem;
  });

  const updatedItems = Object.values(itemsMap);

  return updatedItems;
}
