const VACATION_TYPE_KEYS = {
  ANNUAL: 'ANNUAL',
  HARMFUL_CONDITIONS: 'HARMFUL_CONDITIONS',
  HAS_CHILDREN: 'HAS_CHILDREN'
};

export const VACATION_TYPES = {
  [VACATION_TYPE_KEYS.ANNUAL]: {
    name: 'Відпустка щорічна основна',
    identifier: '14d57070-051e-48bf-b93c-35baa5a4fb64'
  },
  [VACATION_TYPE_KEYS.HAS_CHILDREN]: {
    name: 'Відпустка працівникам, які мають дітей',
    identifier: '2e23e9a2-cab5-11de-b709-000423ddff74'
  }
};

export const ANNUAL_VACATION_TYPE = VACATION_TYPES[VACATION_TYPE_KEYS.ANNUAL];
export const HAS_CHILDREN_VACATION_TYPE = VACATION_TYPES[VACATION_TYPE_KEYS.HAS_CHILDREN];
