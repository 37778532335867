import { ACCEPTED_FILE_EXTENSIONS, MIME_TYPES_BY_BASE_64 } from 'src/constants/fileTypes';
import type { IPreviewDocument, ISignApplicationDocument } from 'src/types/leaveApplications/types';
import { pdfToBase64Image } from '../pdfToBase64Image';

function detectMimeType(b64: string): string {
  for (const [signature, mimeType] of Object.entries(MIME_TYPES_BY_BASE_64)) {
    if (b64.indexOf(signature) === 0) {
      return mimeType;
    }
  }

  return 'application/octet-stream'
}

export const dumpApplicationDocuments = async (
  data: ISignApplicationDocument[]
): Promise<IPreviewDocument[]> => {
  const promises = data.map(async({ document, vacationId }, i) => {
    const mimeType = detectMimeType(document.content)

    const file = `data:${mimeType};base64,${document.content}`;
    const previewFile = mimeType === ACCEPTED_FILE_EXTENSIONS.PDF ? await pdfToBase64Image(file) : file

    return {
      document: {
        uid: String(document.id),
        name: document.name,
        preview: previewFile,
        url: previewFile
      },
      vacationId
    }
  })

  return await Promise.all(promises)
};
