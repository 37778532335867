import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { type InitialStateProps } from './types';
import {
  submitLeaveApplication,
  deletePartTimeApplications,
  getLeaveApplicationDocuments,
  getLeaveApplicationHistory,
  getLeaveApplicationSignDocument,
  getLeaveApplicationTypesList,
  getLeaveApplicationsList,
  getPartTimeJobs,
  getLeaveApplication,
  submitLeaveApplicationsActions,
  submitLeaveApplicationProcess,
  uploadSignedLeaveApplicationDocument,
  uploadLeaveApplicationDocuments,
  getLeaveApplicationListWithDelay,
  getAllFullPartTimeLeaveApplications,
  validateLeaveApplicationDates
} from './leaveApplications.actions';
import type { ILeaveApplicationEntity } from 'src/types/leaveApplications/types';
import { updateOrAddElement } from 'src/utils/reduxUtils';

const initialState: InitialStateProps = {
  allItems: [],
  pagination: {
    pageSize: 10,
    currentPage: 1
  },
  isLoading: false,
  currentLeaveApplication: {
    data: undefined,
    signDocumentsModalOpen: false,
    signDocuments: [],
    createdLeaveApplicationIds: [],
    partTimeJobs: {
      items: [],
      isLoading: false
    },
    previewDocuments: [],
    history: [],
    isLoading: false,
    isValidationLoading: false,
    isLeaveApplicationSigned: false,
    isLeaveApplicationProcessSubmitted: false,
    isLeaveApplicationActionSubmitted: false,
    isLeaveApplicationValid: false,
    needMainLeaveApplicationUpdate: false,
    needToRemovePartTimeJobLeaveApplications: false
  },
  pendingApplicationId: null,
  filters: {
    dateFrom: null,
    dateTo: null,
    status: null
  },
  leaveApplicationTypes: {
    items: [],
    isLoading: false
  }
};

const leaveApplicationsSlice = createSlice({
  name: 'leaveApplications',
  initialState,
  reducers: {
    cleanUpLeaveApplicationState: () => initialState,
    cleanUpCurrentLeaveApplication: (state) => {
      state.currentLeaveApplication = initialState.currentLeaveApplication;
    },
    setCurrentLeaveApplication: (state, action) => {
      state.currentLeaveApplication.data = action.payload;
    },
    setPagination: (state, action) => {
      const currentPage = action.payload;

      state.pagination.currentPage = currentPage;
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    restFilters: (state) => {
      state.filters = initialState.filters;
    },
    needMainLeaveApplicationUpdate: (state, action) => {
      state.currentLeaveApplication.needMainLeaveApplicationUpdate = action.payload;
    },
    needToRemovePartTimeJobLeaveApplications: (state, action) => {
      state.currentLeaveApplication.needToRemovePartTimeJobLeaveApplications = action.payload;
      state.currentLeaveApplication.isLeaveApplicationValid = false;
    },
    clearCurrentLeaveApplicationDocuments: (state) => {
      state.currentLeaveApplication.signDocuments = [];
      state.currentLeaveApplication.previewDocuments = [];
    },
    clearPartTimeJobLeaveApplications: (state, action) => {
      const parentId = action.payload;

      state.allItems = state.allItems.filter(item => item.parentRequestID_FK !== parentId);
      state.currentLeaveApplication.partTimeJobs.items = [];
    },
    setSignDocumentsModalOpen: (state, action) => {
      state.currentLeaveApplication.signDocumentsModalOpen = action.payload;
    },
    setIsValidationLoading: (state, action) => {
      state.currentLeaveApplication.isValidationLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaveApplication.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentLeaveApplication.data = {
        ...action.payload.leaveApplication,
        admManagerPib: action.payload.admManager?.pib,
        fnManagerPib: action.payload.fnManager?.pib
      }
    });

    builder.addCase(validateLeaveApplicationDates.pending, (state) => {
      state.currentLeaveApplication.isValidationLoading = true;
    });
    builder.addCase(validateLeaveApplicationDates.fulfilled, (state, action) => {
      state.currentLeaveApplication.isValidationLoading = false;
      state.currentLeaveApplication.isLeaveApplicationValid = true;
    });
    builder.addCase(validateLeaveApplicationDates.rejected, (state) => {
      state.currentLeaveApplication.isValidationLoading = false;
      state.currentLeaveApplication.isLeaveApplicationValid = false;
    });

    builder.addCase(getLeaveApplicationsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLeaveApplicationsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allItems = action.payload;
    });
    builder.addCase(getLeaveApplicationsList.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getLeaveApplicationListWithDelay.fulfilled, (state, action) => {
      state.pendingApplicationId = null;
      state.allItems = action.payload;
    });

    builder.addCase(submitLeaveApplication.fulfilled, (state, action) => {
      const { mainLeaveApplication, partTimeLeaveApplications } = action.payload;
      const fullMainLeaveApplication = state.allItems.find((item) => item.vacationId === mainLeaveApplication?.vacationId);

      state.currentLeaveApplication.data = {
        ...state.currentLeaveApplication.data,
        ...(fullMainLeaveApplication as ILeaveApplicationEntity) || {}
      };

      if (partTimeLeaveApplications.length) {
        state.currentLeaveApplication.partTimeJobs.items = state.currentLeaveApplication.partTimeJobs.items.map(
          (item) => {
            const partTimeLeaveApplication = partTimeLeaveApplications.find(i =>
              i.companyIdentifier === item.companyIdentifier &&
              i.positionIdentifier === item.positionIdentifier &&
              i.vacationIdentifier === item.vacationIdentifier
            );

            return {
              ...item,
              vacationId: partTimeLeaveApplication?.vacationId,
              usedDays: partTimeLeaveApplication?.usedDays
            }
          }
        );
      }

      state.currentLeaveApplication.isLoading = false;
      state.currentLeaveApplication.signDocumentsModalOpen = true;
    });

    builder.addCase(deletePartTimeApplications.fulfilled, (state, action) => {
      const ids = action.payload;

      state.allItems = state.allItems.filter(item => !ids.includes(item.vacationId));
      state.currentLeaveApplication.signDocuments = state.currentLeaveApplication.signDocuments.filter(item => !ids.includes(item.vacationId));
    });

    builder.addCase(getLeaveApplicationSignDocument.fulfilled, (state, action) => {
      state.currentLeaveApplication.isLoading = false;

      if (action.payload.signDocuments.length) {
        const { signDocuments, previewDocuments } = action.payload;

        const uniqueSignDocuments = updateOrAddElement(state.currentLeaveApplication.signDocuments, signDocuments);
        const uniquePreviewDocuments = updateOrAddElement(state.currentLeaveApplication.previewDocuments, previewDocuments);

        state.currentLeaveApplication.signDocuments = uniqueSignDocuments;
        state.currentLeaveApplication.previewDocuments = uniquePreviewDocuments;
      }
    });

    builder.addCase(uploadLeaveApplicationDocuments.fulfilled, (state, action) => {
      const previewDocuments = action.payload;

      state.currentLeaveApplication.previewDocuments = [...state.currentLeaveApplication.previewDocuments, ...previewDocuments];
    });

    builder.addCase(uploadSignedLeaveApplicationDocument.pending, (state) => {
      state.currentLeaveApplication.isLoading = true;
      state.currentLeaveApplication.isLeaveApplicationSigned = false;
    });
    builder.addCase(uploadSignedLeaveApplicationDocument.fulfilled, (state) => {
      state.currentLeaveApplication.isLoading = false;
      state.currentLeaveApplication.isLeaveApplicationSigned = true;
    });

    builder.addCase(getLeaveApplicationHistory.fulfilled, (state, action) => {
      state.currentLeaveApplication.isLoading = false;
      state.currentLeaveApplication.history = action.payload;
    });

    builder.addCase(getLeaveApplicationDocuments.fulfilled, (state, action) => {
      state.currentLeaveApplication.previewDocuments = action.payload.previewDocuments;
      state.currentLeaveApplication.signDocuments = action.payload.signDocuments;
    });

    builder.addCase(getLeaveApplicationTypesList.pending, (state) => {
      state.leaveApplicationTypes.isLoading = true;
    });
    builder.addCase(getLeaveApplicationTypesList.fulfilled, (state, action) => {
      state.leaveApplicationTypes.isLoading = false;
      state.leaveApplicationTypes.items = action.payload;
    });
    builder.addCase(getLeaveApplicationTypesList.rejected, (state) => {
      state.leaveApplicationTypes.isLoading = false;
    });

    builder.addCase(getPartTimeJobs.pending, (state) => {
      state.currentLeaveApplication.partTimeJobs.isLoading = true;
    });
    builder.addCase(getPartTimeJobs.fulfilled, (state, action) => {
      const mainVacationId = state.currentLeaveApplication.data?.vacationId;
      const partTimeJobApplications = state.allItems.filter(item => item.parentRequestID_FK === mainVacationId);

      if (partTimeJobApplications.length) {
        const items = action.payload.map(item => {
          const p = partTimeJobApplications.find(obj => obj.positionIdentifier.toLowerCase() === item.positionIdentifier.toLowerCase());

          return {
            ...item,
            vacationId: p?.vacationId
          }
        });
        state.currentLeaveApplication.partTimeJobs.items = items;
      } else {
        state.currentLeaveApplication.partTimeJobs.items = action.payload;
      }
      state.currentLeaveApplication.partTimeJobs.isLoading = false;
    });
    builder.addCase(getPartTimeJobs.rejected, (state) => {
      state.currentLeaveApplication.partTimeJobs.isLoading = false;
    });

    builder.addCase(getAllFullPartTimeLeaveApplications.pending, (state) => {
      state.currentLeaveApplication.partTimeJobs.isLoading = true;
    });
    builder.addCase(getAllFullPartTimeLeaveApplications.fulfilled, (state, action) => {
      state.currentLeaveApplication.partTimeJobs.items = action.payload;
      state.currentLeaveApplication.partTimeJobs.isLoading = false;
    });
    builder.addCase(getAllFullPartTimeLeaveApplications.rejected, (state) => {
      state.currentLeaveApplication.partTimeJobs.isLoading = false;
    });

    builder.addCase(submitLeaveApplicationProcess.fulfilled, (state, action) => {
      const vacationId = action.meta.arg;

      state.pendingApplicationId = vacationId;
      state.currentLeaveApplication.isLeaveApplicationProcessSubmitted = true;
      state.currentLeaveApplication.isLoading = false;
    });

    builder.addCase(submitLeaveApplicationsActions.fulfilled, (state) => {
      state.currentLeaveApplication.isLeaveApplicationActionSubmitted = true;
      state.currentLeaveApplication.isLoading = false;
    });

    builder.addMatcher(
      isAnyOf(
        getLeaveApplication.pending,
        submitLeaveApplication.pending,
        getLeaveApplicationSignDocument.pending,
        getLeaveApplicationHistory.pending,
        submitLeaveApplicationProcess.pending,
        submitLeaveApplicationsActions.pending
      ),
      (state) => {
        state.currentLeaveApplication.isLoading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getLeaveApplication.rejected,
        submitLeaveApplication.rejected,
        getLeaveApplicationSignDocument.rejected,
        uploadSignedLeaveApplicationDocument.rejected,
        getLeaveApplicationHistory.rejected,
        submitLeaveApplicationProcess.rejected,
        submitLeaveApplicationsActions.rejected
      ),
      (state) => {
        state.currentLeaveApplication.isLoading = false;
      }
    );
  }
});

export const {
  cleanUpLeaveApplicationState,
  cleanUpCurrentLeaveApplication,
  setCurrentLeaveApplication,
  setPagination,
  setFilters,
  restFilters,
  needMainLeaveApplicationUpdate,
  clearCurrentLeaveApplicationDocuments,
  clearPartTimeJobLeaveApplications,
  needToRemovePartTimeJobLeaveApplications,
  setSignDocumentsModalOpen,
  setIsValidationLoading
} = leaveApplicationsSlice.actions;

export default leaveApplicationsSlice.reducer;
