import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
`;

interface IProps {
  children: React.ReactNode;
}

export const LoginLayout: React.FC<IProps> = ({ children }) => {
  return <Container>{children}</Container>;
};
