import React from 'react';

import { Col, type ColProps } from 'antd';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const CustomCol: React.FC<IProps | ColProps> = (props) => {
  const { className = '', children } = props;

  return (
    <Col className={className} {...props}>
      {children}
    </Col>
  );
};

export { CustomCol as Col };
