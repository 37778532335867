import React from 'react';
import styled from 'styled-components';

import { PlusOutlined } from 'src/components';

import { Button, Flex, Grid, Typography } from 'antd';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  margin: 0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
`;

interface IProps {
  onCreate: () => void;
}

export const LeaveApplicationHeader: React.FC<IProps> = ({ onCreate }) => {
  const screen = useBreakpoint();

  return (
    <TableHeader>
      <StyledTitle level={3}>Мої відпустки</StyledTitle>
      <Flex gap={16} vertical={screen.sm && !screen.md}>
        <div>
          <StyledButton
            data-testid="createLeaveButton"
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={onCreate}
          >
            {!screen.sm ? '' : 'Створити заявку'}
          </StyledButton>
        </div>
      </Flex>
    </TableHeader>
  );
};
