import { getDocument } from 'pdfjs-dist';

import 'pdfjs-dist/build/pdf.worker.mjs';

type Base64 = string;

export async function pdfToBase64Image(base64?: Base64) {
  if (!base64) return;

  const pdf = getDocument(base64);
  const doc = await pdf.promise;
  const page = await doc.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const renderContext = { canvasContext: ctx, viewport };

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const res = page.render(renderContext);
  await res.promise;

  const base64Str = canvas.toDataURL();

  return base64Str;
}
