import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'src/store/rootStore';
import { type Filters, type ILeaveApplicationEntity } from 'src/types/leaveApplications/types';
import { LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';
import { dateToTimeZone } from 'src/utils/date';

const getState = (state: RootState) => state.leaveApplications;

const filterApplicationsByDate = (items: ILeaveApplicationEntity[], filter: Filters) => {
  return items.filter((item) => {
    if (!filter.dateFrom && !filter.dateTo) return true;

    const itemDateFrom = dateToTimeZone(moment(item.dateFrom));
    const itemDateTo = dateToTimeZone(moment(item.dateTo));
    const dateFromFilter = filter.dateFrom ? dateToTimeZone(moment(filter.dateFrom)) : null;
    const dateToFilter = filter.dateTo ? dateToTimeZone(moment(filter.dateTo)) : null;

    const isDateFrom = itemDateFrom?.isSame(dateFromFilter, 'day');
    const isDateTo = itemDateTo?.isSame(dateToFilter, 'day');
    const isDateInRange = itemDateFrom.isSameOrAfter(dateFromFilter) && itemDateTo.isSameOrBefore(dateToFilter);

    return isDateInRange || isDateFrom || isDateTo;
  });
}

const filterApplicationsByStatus = (items: ILeaveApplicationEntity[], filter: Filters) => {
  return items.filter((item) => {
    if (!filter.status) return true;

    return LeaveApplicationStatusId[item.statusId] === filter.status;
  });
}

const filterMainApplications = (items: ILeaveApplicationEntity[]) => {
  return items.filter((item) => !item.parentRequestID_FK);
}

export const leaveApplicationItemsSelector = createSelector(getState, (state) => {
  const filteredItems =
      filterApplicationsByStatus(
        filterApplicationsByDate(
          filterMainApplications(state.allItems), state.filters
        ), state.filters
      );

  return filteredItems.reverse();
});

export const leaveApplicationPaginationSelector = createSelector(getState, (state) => {
  const filteredItems =
    filterApplicationsByStatus(
      filterApplicationsByDate(
        filterMainApplications(state.allItems), state.filters
      ), state.filters
    );

  return {
    ...state.pagination,
    totalCount: filteredItems.length
  }
});

export const existentPartTimeJobsLeaveApplicationsSelector = createSelector(getState, (state) => {
  const mainLeaveApplication = state.currentLeaveApplication.data;

  return state.allItems.filter(
    (item: ILeaveApplicationEntity) => item.parentRequestID_FK === mainLeaveApplication?.vacationId
  );
});

export const leaveApplicationAllItemsSelector = createSelector(getState, (state) => state.allItems);
export const leaveApplicationItemsIsLoadingSelector = createSelector(getState, (state) => state.isLoading);
export const currentLeaveApplicationSelector = createSelector(getState, (state) => state.currentLeaveApplication);
export const currentLeaveApplicationDataSelector = createSelector(getState, (state) => state.currentLeaveApplication.data);
export const currentLeaveApplicationPartTimeJobsSelector = createSelector(getState, (state) => state.currentLeaveApplication.partTimeJobs.items);
export const currentLeaveApplicationPartTimeJobsIsLoadingSelector = createSelector(getState, (state) => state.currentLeaveApplication.partTimeJobs.isLoading);
export const currentLeaveApplicationHistorySelector = createSelector(getState, (state) => state.currentLeaveApplication.history);
export const currentLeaveApplicationPreviewDocumentsSelector = createSelector(getState, (state) => state.currentLeaveApplication.previewDocuments);
export const leaveApplicationFiltersSelector = createSelector(getState, (state) => state.filters);
export const currentLeaveApplicationSignDocumentSelector = createSelector(getState, (state) => state.currentLeaveApplication.signDocuments);
export const currentLeaveApplicationIsSignedSelector = createSelector(getState, (state) => state.currentLeaveApplication.isLeaveApplicationSigned);
export const isCurrentLeaveApplicationValidSelector = createSelector(getState, (state) => state.currentLeaveApplication.isLeaveApplicationValid);
export const leaveApplicationTypesSelector = createSelector(getState, (state) => state.leaveApplicationTypes.items);
export const leaveApplicationTypesIsLoadingSelector = createSelector(getState, (state) => state.leaveApplicationTypes.isLoading);
export const leaveApplicationInReworkStatusSelector = createSelector(getState, (state) => {
  return state.allItems.filter((item) => LeaveApplicationStatusId[item.statusId] === LeaveApplicationStatus.rework);
});
export const pendingApplicationIdSelector = createSelector(getState, (state) => state.pendingApplicationId);

export const partTimeJobsItemsSelector = createSelector(
  existentPartTimeJobsLeaveApplicationsSelector,
  currentLeaveApplicationPartTimeJobsSelector,
  (existentPartTimeJobsLeaveApplications, partTimeJobs) => {
    const items = partTimeJobs.map((item) => {
      if (!existentPartTimeJobsLeaveApplications.length) {
        return {
          ...item,
          isActive: true,
          disabled: false
        };
      }

      const partTimeJobsLeaveApplications = existentPartTimeJobsLeaveApplications.find(
        application => application.companyIdentifier === item.companyIdentifier &&
          application.positionIdentifier === item.positionIdentifier &&
          application.vacationIdentifier === item.vacationIdentifier
      );

      return {
        ...item,
        isActive: !!partTimeJobsLeaveApplications,
        disabled: !!partTimeJobsLeaveApplications
      }
    });

    return { items };
  });
