import type {
  ConfirmLogoutModalProps,
  ConfirmCancelModalProps,
  SignLeaveApplicationModalProps,
  DeleteLeaveApplicationModalProps,
  LeaveApplicationIsSignedModalProps
} from 'src/features/ui/modals';
import { type RedirectType } from '../types';
import { type UserIdleModalProps } from 'src/features/ui/modals/UserIdleModal';
import { type ConfirmRemoveFileModalProps } from 'src/features/ui/modals/ConfirmRemoveFileModal';
import { type ErrorModalProps } from 'src/features/ui/modals/ErrorModal';

export enum ModalType {
  ConfirmCancelModal = 'ConfirmCancelModal',
  DeleteLeaveApplication = 'DeleteLeaveApplication',
  SignLeaveApplication = 'SignLeaveApplication',
  LeaveApplicationIsSigned = 'LeaveApplicationIsSigned',
  UserIdle = 'UserIdle',
  ConfirmLogout = 'ConfirmLogout',
  ConfirmRemoveFile = 'ConfirmRemoveFile',
  ErrorModal = 'ErrorModal'
}

type ModalsPropsMap = {
  [ModalType.ConfirmCancelModal]: ConfirmCancelModalProps;
  [ModalType.DeleteLeaveApplication]: DeleteLeaveApplicationModalProps;
  [ModalType.SignLeaveApplication]: SignLeaveApplicationModalProps;
  [ModalType.LeaveApplicationIsSigned]: LeaveApplicationIsSignedModalProps;
  [ModalType.UserIdle]: UserIdleModalProps;
  [ModalType.ConfirmLogout]: ConfirmLogoutModalProps;
  [ModalType.ConfirmRemoveFile]: ConfirmRemoveFileModalProps;
  [ModalType.ErrorModal]: ErrorModalProps;
};

export type ModalProps<T> = T extends ModalType ? ModalsPropsMap[T] : never;

export interface Modal {
  type: ModalType;
  componentProps: ModalProps<ModalType>;
}

export interface InitialStateProps {
  activeModals: Modal[];
}

export interface ILoginPayload {
  credentials: { email?: string; password?: string; secret?: string };
  redirectTo: RedirectType;
}

export interface IResetEmailPayload {
  credentials: { email?: string };
  redirectTo: RedirectType;
}

export interface IPasswordPayload {
  credentials: { password?: string; confirmPassword?: string };
  redirectTo: RedirectType;
  id: string;
}

export interface IRedirect {
  redirectTo: RedirectType;
}
