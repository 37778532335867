import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Typography, Button, Flex, Grid, Form } from 'antd';
import { PhoneInput } from 'react-international-phone';
import styled from 'styled-components';
import 'react-international-phone/style.css';

import { PHONE_NUMBER_LENGTH } from 'src/constants/phoneNumber';
import { ErrorText } from 'src/components/Inputs/Input/ErrorText/ErrorText';
import { useDispatch } from 'react-redux';
import { clearSessionsError } from 'src/store/sessions';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DescriptionWrapper = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  margin-bottom: 40px;
  text-align: ${(props) => props.$isMobile ? 'center' : ''}
`;

const ButtonContainer = styled(Flex)`
  padding-top: 32px;
`;

const ErrorContainer = styled(Flex)`
  margin-top: 5px;
  height: 55px;
`;

const StyledPhoneInput = styled(PhoneInput)<{ isActiveInput: boolean }>`
  --react-international-phone-border-color: ${(props) => props.isActiveInput ? props.theme.colors.borderHover : props.theme.colors.borderColor};
  --react-international-phone-border-radius: 8px;
  --react-international-phone-height: 40px;
  --react-international-phone-font-size: 16px;

  .react-international-phone-dial-code-preview {
    border-width: 2px;
    border-right-width: 1px;
    border-radius: 8px 0 0 8px;
  }

  &:hover {
    --react-international-phone-border-color: ${(props) => props.theme.colors.borderHover};

    .react-international-phone-input {
      border: 2px solid ${(props) => props.theme.colors.borderHover};
    }
  }

  .react-international-phone-input {
    border: 2px solid ${(props) => props.isActiveInput ? props.theme.colors.borderHover : props.theme.colors.borderColor};
    width: 100%;
    height: 40px;
  }

  .react-international-phone-country-selector-button {
    display: none;
  }
`;

export interface ILoginData {
  phone: string;
}

interface IProps {
  loading: boolean;
  error: any;
  onSubmit: (data: ILoginData) => void;
  removeError?: (value: string) => void;
}

export const LoginForm: React.FC<IProps> = (props) => {
  const { loading, error, onSubmit, removeError = null } = props;
  const [isActiveInput, setIsActiveInput] = useState(false);
  const screen = useBreakpoint();
  const dispatch = useDispatch();

  const [data, setData] = useState<ILoginData>({ phone: '' });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = useCallback(
    (value: string, name: string) => {
      dispatch(clearSessionsError());

      setData((prev) => ({
        ...prev,
        [name]: value
      }));

      setIsDisabled(!(value.length >= PHONE_NUMBER_LENGTH));

      if (error && removeError) removeError(name);
    },
    [removeError, error, dispatch]
  );

  const handleSubmit = useCallback(() => {
    onSubmit({ phone: data.phone.slice(3) });
  }, [onSubmit, data]);

  const debouncedSubmit = useMemo(() => {
    return debounce(handleSubmit, 500);
  }, [handleSubmit]);

  return (
    <div data-testid='LoginForm'>
      <TitleWrapper>
        <Title level={5}>Вхід в інфо-бокс</Title>
      </TitleWrapper>
      <DescriptionWrapper $isMobile={!screen.sm}>
        <Text type='secondary'>Введіть Ваш особистий номер телефону для входу в систему</Text>
      </DescriptionWrapper>
      <Text type='secondary'>Номер телефону</Text>
      <Form onFinish={debouncedSubmit}> { /* trigger on Enter keypress */}
        <StyledPhoneInput
          hideDropdown
          placeholder='(XX) XXX XX XX'
          defaultCountry="ua"
          value={data.phone}
          onChange={(phone) => { handleChange(phone, 'phone') }}
          disableDialCodeAndPrefix
          showDisabledDialCodeAndPrefix
          isActiveInput={isActiveInput}
          onFocus={() => { setIsActiveInput(true) }}
          onBlur={() => { setIsActiveInput(false) }}
        />
        <ErrorContainer data-testid='error'>
          {error && <ErrorText>{error}</ErrorText>}
        </ErrorContainer>
        <ButtonContainer>
          <Button
            block
            data-testid='login'
            type='primary'
            size='large'
            disabled={isDisabled}
            onClick={window.Cypress ? handleSubmit : debouncedSubmit}
            loading={loading}
          >
            Продовжити
          </Button>
        </ButtonContainer>
      </Form>
    </div>
  );
};
