import { type compose } from 'redux';

import { configureStore } from '@reduxjs/toolkit';

import sessionsReducer from './sessions';
import modalsReducer from 'src/store/modals';
import leaveApplicationsReducer from 'src/store/leaveApplications';

export const rootStore = configureStore({
  reducer: {
    sessions: sessionsReducer,
    modals: modalsReducer,
    leaveApplications: leaveApplicationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
