import { Grid, Table } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { StackTable } from 'src/components/StackTable/StackTable';
import { type ILeaveApplicationHistory } from 'src/types/history/types';
import { formatDate } from 'src/utils/date';
import styled from 'styled-components';

const { useBreakpoint } = Grid;

const Container = styled.div`
  padding: 0 28px;
`;

const StyledTable = styled(Table)<any>`
  width: auto;

  .ant-table-cell {
    padding: 14px !important;
  }

  .ant-table-row > td {
    font-size: ${(props) => (props.screen.xl ? '16px' : '14px')};
  }
`;

interface Props {
  data: ILeaveApplicationHistory[];
}

export const History: React.FC<Props> = ({ data }) => {
  const screen = useBreakpoint();
  const isDesktop = !!screen.md;

  const columns: ColumnsType<ILeaveApplicationHistory> = useMemo(
    () => [
      {
        title: 'Співробітник',
        key: 'displayName',
        dataIndex: 'displayName',
        width: '20%'
      },
      {
        title: 'Посада',
        key: 'position',
        dataIndex: 'position',
        width: '20%'
      },
      {
        title: 'Дата',
        key: 'eventDate',
        dataIndex: 'eventDate',
        width: '20%',
        render: (_, record) => {
          return formatDate(record.eventDate, 'DD.MM.YYYY HH:mm');
        }
      },
      {
        title: 'Подія',
        key: 'eventInfo',
        dataIndex: 'eventInfo'
      }
    ],
    []
  );

  return (
    <Container>
      {isDesktop
        ? <StyledTable
            columns={columns}
            dataSource={data}
            screen={screen}
            pagination={false}
            tableLayout='fixed'
            rowKey={(record: ILeaveApplicationHistory) => record.id}
          />
        : <StackTable columns={columns} dataSource={data} />
      }
    </Container>
  );
};
