/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import { Row, Col } from 'antd';

import { Header } from 'src/features/ui';

import { useModal } from 'src/hooks/useModal';
import { logout } from 'src/store/sessions';
import { ModalType } from 'src/store/modals/types';
import { useModalActive } from 'src/store/modals';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from 'src/store/rootStore';

const Container = styled.div`
  padding-top: 24px;
`;

interface IProps {
  children: React.ReactNode;
}

const IDLE_TIMEOUT = 1000 * 120;

export const AuthLayout: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, closeModal] = useModal(ModalType.UserIdle);
  const isActiveUserIdleModal = useModalActive(ModalType.UserIdle);

  const handleLogout = () => {
    const payload = {
      redirectTo: navigate
    };

    dispatch(logout(payload));
    closeModal();
  };

  const onIdle = () => {
    if (!isActiveUserIdleModal) {
      openModal({ onLogout: handleLogout, onClose: closeModal });
    }
  }

  useIdleTimer({
    onIdle,
    timeout: IDLE_TIMEOUT,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  });

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};
