type RouteParams = Record<string, string | number>;

export const generatePathWithParams = (route: string, params: RouteParams): string => {
  let path = route;

  Object.keys(params).forEach(param => {
    path = path.replace(`:${param}`, String(params[param]));
  });

  return path;
};
