import React, { useCallback } from 'react';
import { Typography, Button, Flex, Grid } from 'antd';
import { LeftOutlined } from 'src/components';

import styled from 'styled-components';
import Countdown from 'src/components/Countdown/Countdown';
import { useSelector } from 'react-redux';
import { userBlockedTimestampSelector } from 'src/store/sessions';
import moment from 'moment';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LeftOutlinedStyled = styled(LeftOutlined)`
  color: ${props => props.theme.antd.colorTextSecondary};
  margin-right: 10px;
`;

const DescriptionWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin-bottom: 40px;
  text-align: ${(props) => props.isMobile ? 'center' : ''}
`;

const CountdownContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled(Flex)`
  padding-top: 32px;
`;

interface IProps {
  onClick: () => void;
}

const MINUTE_SECONDS = 60;
const COUNTDOWN_DURATION = MINUTE_SECONDS * 5;

export const TimerForm: React.FC<IProps> = (props) => {
  const { onClick } = props;
  const screen = useBreakpoint();
  const userBlockedTimestamp = useSelector(userBlockedTimestampSelector);
  const countdownDuration = userBlockedTimestamp ? COUNTDOWN_DURATION - moment().diff(userBlockedTimestamp, 'seconds') : COUNTDOWN_DURATION;

  const handleOnComplete = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div data-testid='TimerForm'>
      <TitleWrapper>
        <Title level={5}><LeftOutlinedStyled />Введіть код доступу</Title>
      </TitleWrapper>
      <DescriptionWrapper isMobile={!screen.sm}>
        <Text type='secondary'>
          Шановний співробітник. На жаль, Ви невірно ввели код доступу 3 рази. Ваш обліковий запис заблоковано на 5 хвилин.
          <br /><br />
          Будь ласка, спробуйте авторизуватись через 5 хвилин та будьте уважні, коли будете вводити пароль
        </Text>
      </DescriptionWrapper>
      <CountdownContainer>
        <Countdown countdownDuration={countdownDuration} onComplete={handleOnComplete} />
      </CountdownContainer>
      <ButtonContainer>
        <Button
          size='large'
          block
          onClick={handleOnComplete}
        >
          Повернутись на головну
        </Button>
      </ButtonContainer>
    </div>
  );
};
