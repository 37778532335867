import React from 'react';
import cx from 'classnames';

import styles from './indicatePassword.module.scss';

interface IProps {
  rules: any;
}

export const IndicatePassword: React.FC<IProps> = (props) => {
  const { rules } = props;

  const sortedRules = Object.entries(rules).sort(([, value]) => (value ? -1 : 1));

  const isTrue = sortedRules.some(([, value]) => value);

  return (
    <span className={styles.IndicateWrapper}>
      {sortedRules.map(([key, value]) => (
        <span
          key={key}
          className={cx({
            [styles.IndicateItem]: true,
            [styles.Success]: value,
            [styles.Error]: isTrue && !value
          })}
        />
      ))}
    </span>
  );
};
