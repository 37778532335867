/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { ROUTES } from 'src/constants/routes';

const { Title } = Typography;

export function Fallback() {
  const handleRedirect = () => {
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    window.location = ROUTES.LOGIN;
  };

  return (
    <Flex vertical justify='center' align='center' style={{ height: '100vh' }}>
      <Title level={3} type="danger">Щось пішло не так</Title>
      <Button onClick={handleRedirect}>Спробувати ще раз</Button>
    </Flex>
  );
}
