import React from 'react';
import styled from 'styled-components';

import { Typography, Flex } from 'antd';

const { Text } = Typography;

const Container = styled(Flex)`
  height: 62px;
  justify-content: space-between;
`;

interface IProps {
  title: string;
  text: string;
  testId?: string;
}

export const InfoField: React.FC<IProps> = ({ title, text, testId }) => {
  return (
    <Container data-testid={testId} vertical>
      <Text type='secondary'>{title}</Text>
      <Text ellipsis>{text}</Text>
    </Container>
  );
};
