import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
`;

interface IProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<IProps> = ({ children }) => {
  return <Container>{children}</Container>;
};
