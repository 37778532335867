import { type IProfile } from 'src/types/user/types';

export enum SessionView {
  login = 'login',
  confirmCode = 'confirmCode',
  timer = 'timer'
}

export interface InitialStateProps {
  error: string | null;
  userPhone: string;
  userPib: string;
  userBlockedTimestamp: number | null;
  sessionView: SessionView;
  isLoadingRequestCode: boolean;
  isLoadingConfirmCode: boolean;
  profile: IProfile | null;
  partTimeJobProfiles: {
    items: IProfile[],
    isLoading: boolean
  }
}

export interface ILoginPayload {
  credentials: { phone?: string; code?: string };
}
