import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/constants/routes';
import { useModal } from 'src/hooks/useModal';
import { ModalType } from 'src/store/modals/types';
import { type IValidationError } from 'src/types/common';
import { STEPPER_MODE, type ILeaveApplicationFormData } from 'src/types/leaveApplications/types';
import { STEP_NUMBER_BY_NAME } from './useStepperForm';
import { LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';

interface IProps {
  formData: ILeaveApplicationFormData;
  errors: Record<number, IValidationError>;
  showPartTimeJobsStep: boolean;
  mode: STEPPER_MODE;
  onSubmit: (data: ILeaveApplicationFormData) => void;
  resetStepErrors: (step: number) => void;
  stepValidators: Array<() => Promise<boolean>>
}

export const useValidateApplicationStepper = ({
  formData,
  errors,
  showPartTimeJobsStep,
  mode,
  onSubmit,
  resetStepErrors,
  stepValidators
}: IProps) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(STEP_NUMBER_BY_NAME.info);

  const isCurrentStepValid = !Object.keys(errors[currentStep]).length;
  const isLastStep = showPartTimeJobsStep
    ? currentStep === STEP_NUMBER_BY_NAME.partTimeJobs
    : currentStep === STEP_NUMBER_BY_NAME.documents;

  const handleNext = async () => {
    const isValid = await stepValidators[currentStep]();

    if (!isValid) return;

    if (isLastStep) {
      onSubmit(formData)

      return;
    }

    setCurrentStep(currentStep + 1);
    resetStepErrors(currentStep);
  };

  const handlePrev = () => {
    const newStep = currentStep - 1;

    if (newStep === -1) {
      handleCancel();
      return;
    }

    setCurrentStep(newStep);
    resetStepErrors(currentStep);
  };

  const [openConfirmCancelModal, closeConfirmCancelModal] = useModal(ModalType.ConfirmCancelModal);

  const handleCancel = useCallback(() => {
    if (LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
      navigate(ROUTES.LEAVE_APPLICATIONS);
      return;
    }

    let confirmCancelTitle = 'Скасувати створення заявки';
    let confirmCancelText = 'Ви впевнені, що хочете скасувати створення заявки? Усі введені дані будуть втрачені, і заявка не буде збережена';

    if (mode === STEPPER_MODE.EDIT) {
      confirmCancelTitle = 'Скасувати редагування заявки';
      confirmCancelText = 'Ви впевнені, що хочете скасувати редагування заявки? Усі введені дані будуть втрачені, і заявка не буде збережена';
    }

    openConfirmCancelModal({
      title: confirmCancelTitle,
      text: confirmCancelText,
      onSubmit: () => {
        navigate(ROUTES.LEAVE_APPLICATIONS);
        closeConfirmCancelModal();
      },
      onClose: closeConfirmCancelModal
    });
  }, [navigate, openConfirmCancelModal, closeConfirmCancelModal]);

  return {
    currentStep,
    isCurrentStepValid,
    currentStepErrors: errors[currentStep],
    isLastStep,
    handleCancel,
    handleNext,
    handlePrev
  };
};
