import React, { type ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import { type Dispatch } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ConfigProvider } from 'antd';
import { jwtDecode } from 'jwt-decode';
import 'moment-timezone';
import 'isomorphic-fetch';

import { App } from './app/App';
import { ConfigProviderLayout } from './features/layout';
import reportWebVitals from './reportWebVitals';

import { rootStore } from './store/rootStore';
import { defaultTheme } from './features/theme';
import ThemeProvider from './features/theme/ThemeProvider';
import { Fallback } from './features/pages/Fallback/Fallback';

import './assets/variabless.scss';
import { sessionStorage } from './utils/storage';
import api from 'src/app/apiSingleton';
import { setUserProfile } from './store/sessions';

function logError(error: Error, info: ErrorInfo) {
  console.error('Caught an error:', error, info);
}

function initUserProfile(dispatch: Dispatch) {
  const token = sessionStorage.get('sessionToken');

  if (token) {
    const user = jwtDecode(token);

    api.apiClient.setToken(token);
    dispatch(setUserProfile(user));
  }
}

rootStore.dispatch(initUserProfile);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
    <Provider store={rootStore}>
      <ConfigProvider theme={defaultTheme}>
        <ThemeProvider>
          <ConfigProviderLayout>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProviderLayout>
        </ThemeProvider>
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
