import React from 'react';
import {
  ConfirmLogoutModal,
  ConfirmCancelModal,
  UserIdleModal,
  DeleteLeaveApplicationModal,
  LeaveApplicationIsSignedModal,
  SignLeaveApplicationModal
} from 'src/features/ui/modals';
import { ConfirmRemoveFileModal } from 'src/features/ui/modals/ConfirmRemoveFileModal';
import { ErrorModal } from 'src/features/ui/modals/ErrorModal';
import { useActiveModals } from 'src/store/modals';
import { ModalType } from 'src/store/modals/types';

const MODALS: { [key in ModalType]: React.ElementType } = {
  [ModalType.ConfirmCancelModal]: ConfirmCancelModal,
  [ModalType.DeleteLeaveApplication]: DeleteLeaveApplicationModal,
  [ModalType.SignLeaveApplication]: SignLeaveApplicationModal,
  [ModalType.LeaveApplicationIsSigned]: LeaveApplicationIsSignedModal,
  [ModalType.UserIdle]: UserIdleModal,
  [ModalType.ConfirmLogout]: ConfirmLogoutModal,
  [ModalType.ConfirmRemoveFile]: ConfirmRemoveFileModal,
  [ModalType.ErrorModal]: ErrorModal
};
export function ModalLayout() {
  const activeModals = useActiveModals();

  return (
    <>
      {activeModals.map(({ type, componentProps }) => {
        const Modal = MODALS[type];

        return <Modal key={type} {...componentProps} />;
      })}
    </>
  );
}
