import { type UploadFile } from 'antd';
import { type RcFile } from 'antd/es/upload';

import { pdfToBase64Image } from '../pdfToBase64Image';

export const getBase64Src = async (imgObj: File | null): Promise<string | undefined> => {
  if (!imgObj) return;

  return await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(imgObj);
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getBase64Img = async (file: UploadFile, allowedPreviewExtensions: string[]) => {
  if (file.type && allowedPreviewExtensions.includes(file.type)) {
    const base64 = await getBase64Src(file.originFileObj as RcFile);

    if (file.type === 'application/pdf') {
      return await pdfToBase64Image(base64);
    }

    return base64;
  }
}
