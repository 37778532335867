import { createSlice } from '@reduxjs/toolkit';

import { type InitialStateProps } from './types';

const initialState: InitialStateProps = {
  activeModals: []
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const isModal = state.activeModals.find(item => item.type === action.payload.type);

      if (!isModal) {
        state.activeModals.push(action.payload);
      }
    },
    closeModal: (state, action) => {
      state.activeModals = state.activeModals.filter((modal) => modal.type !== action.payload.type);
    },
    updateModal: (state, action) => {
      state.activeModals = state.activeModals.map((dialog) => {
        if (dialog.type === action.payload.type) {
          return {
            ...dialog,
            componentProps: {
              ...dialog.componentProps,
              ...action.payload.componentProps
            }
          };
        }

        return dialog;
      })
    },
    clearModalState: (state) => {
      state.activeModals = [];
    }
  }
});

export const { openModal, closeModal, updateModal, clearModalState } = modalsSlice.actions;

export default modalsSlice.reducer;
