import { type UploadFile } from 'antd';
import { useCallback, useState } from 'react';
import { ALLOWED_PREVIEW_TYPES } from 'src/constants/fileTypes';
import { getBase64Img } from 'src/utils/image/getBase64Src';

export function usePreviewDocument() {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = useCallback(() => {
    setPreviewOpen(false);
  }, []);

  const handlePreview = useCallback(async (file: UploadFile) => {
    if (!file.preview) {
      const base64Img = await getBase64Img(file, ALLOWED_PREVIEW_TYPES);

      file.preview = base64Img;
    }

    const isPreviewAllowed = ALLOWED_PREVIEW_TYPES.some((item) => file.preview?.includes(item));

    if (file.preview && isPreviewAllowed) {
      setPreviewImage(file.preview);
      setPreviewTitle(file.name);
      setPreviewOpen(true);
    }
  }, []);

  return { previewOpen, previewImage, previewTitle, handlePreview, handleCancel };
}
