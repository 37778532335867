import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'src/assets/images/warning.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/close.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props) => props.theme.colors.warningLight};
  border: 1px solid ${(props) => props.theme.colors.warning};
  border-radius: 2px;
  padding: 8px 16px;
  margin: 0px 28px;
`;

const CorrectionList = styled.ul`
  list-style-type: '- ';
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
`;

export const FamiliarizationWarning = () => {
  const [showWarning, setShowWarning] = useState(true);

  function handleHideWarning() {
    setShowWarning(false);
  }

  if (!showWarning) return null;

  return (
    <Container>
      <div>
        <WarningIcon width={14} />
      </div>
      <div style={{ width: '100%' }}>
        <Typography.Text style={{ fontWeight: 500 }}>
          Звертаємо Вашу увагу на необхідні зміни у Вашій заявці на відпустку:
        </Typography.Text>
        <CorrectionList>
          <li>
            <Typography.Text>Тип відпустки: </Typography.Text>
            <Typography.Text style={{ fontWeight: 400 }}>з [поточний тип відпустки] на [новий тип відпустки]</Typography.Text>
          </li>
          <li>
            <Typography.Text>Підприємство: </Typography.Text>
            <Typography.Text style={{ fontWeight: 400 }}>з [поточного підприємства]  на [нове підприємство]</Typography.Text>
          </li>
          <li>
            <Typography.Text>Підрозділ: </Typography.Text>
            <Typography.Text style={{ fontWeight: 400 }}>з [поточного підрозділу] на [новий підрозділ].</Typography.Text>
          </li>
        </CorrectionList>
      </div>
      <Button
        type="text"
        shape="circle"
        onClick={handleHideWarning}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 24,
          height: 24
        }}
      >
        <CloseIcon />
      </Button>
    </Container>
  );
};
