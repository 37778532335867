import { Flex, Select, Spin, Typography } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { type SelectProps } from 'antd/lib';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import { parseServerError } from 'src/constants/error';
import { errorsCodes } from 'src/constants/errors/codes';
import { useModal } from 'src/hooks/useModal';
import { ModalType } from 'src/store/modals/types';
import styled from 'styled-components';

const { Text } = Typography;

const Container = styled(Flex)`
  height: 72px;
  justify-content: space-between;
`;

interface IProps {
  title: string;
  value?: DefaultOptionType;
  onChange?: SelectProps['onChange'];
  testId?: string;
  fetcher: (payload: any) => Promise<DefaultOptionType[]>;
  getFetcherPayload?: (value: string) => unknown;
  filter?: (options: DefaultOptionType[]) => DefaultOptionType[];
  debounceTimeout?: number;
  disabled?: boolean;
}

export const DebounceSelect: React.FC<IProps> = ({
  testId,
  title,
  value,
  onChange,
  fetcher,
  filter,
  getFetcherPayload,
  debounceTimeout = 500,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [openModal] = useModal(ModalType.ErrorModal);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      if (!value) return

      try {
        setOptions([]);
        setIsLoading(true);

        const payload = getFetcherPayload ? getFetcherPayload(value) : value;
        const newOptions = await fetcher(payload);
        const filteredOptions = filter ? filter(newOptions) : newOptions;

        setOptions(filteredOptions);
      } catch (err: any) {
        const { message } = parseServerError(err);

        if (err.code === errorsCodes.SERVER_ERROR) {
          openModal({
            title: 'Помилка',
            message
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetcher, getFetcherPayload, filter, debounceTimeout]);

  return (
    <Container data-testid={testId} vertical>
      <Text type="secondary">{title}</Text>
      <Select
        labelInValue
        filterOption={false}
        showSearch
        onSearch={debounceFetcher}
        value={value}
        onChange={onChange}
        notFoundContent={
          isLoading ? (
            <Flex style={{ padding: 8 }}>
              <Spin size="small" />
            </Flex>
          ) : null
        }
        {...props}
        options={options}
        style={{ width: '100%', height: 40 }}
      />
    </Container>
  );
};
