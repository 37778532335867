import React from 'react';
import styled from 'styled-components';

import { Col, Row } from 'src/components';
import { CENTER, MIDDLE } from 'src/constants/components/row';

const Content = styled.div`
  height: auto;
  box-shadow: ${(props) => props.theme.antd.boxShadow};
  border-radius: ${(props) => props.theme.antd.borderRadius}px;
  background: ${(props) => props.theme.antd.colorBgBase};
  padding: 28px 0;
  border-radius: 20px;
  margin-bottom: 28px;
`;

interface IProps {
  children: React.ReactNode;
}

export const LeaveApplicationContainer: React.FC<IProps> = ({ children }) => {
  return (
    <Row justify={CENTER} align={MIDDLE} data-testid="CreateLeave">
      <Col xs={24} lg={24}>
        <Content>{children}</Content>
      </Col>
    </Row>
  );
};
