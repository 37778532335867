import { ConfigProvider } from 'antd';
import ukUA from 'antd/lib/locale/uk_UA';
import React from 'react';

interface IProps {
  children: React.ReactNode;
}

export const ConfigProviderLayout: React.FC<IProps> = (props) => {
  const { children } = props;

  return (
    <ConfigProvider
      locale={ukUA}
      getPopupContainer={(trigger) => trigger?.parentElement as HTMLElement}
    >
      {children}
    </ConfigProvider>
  );
};
