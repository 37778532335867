import { type Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
  updateModal as updateModalAction
} from 'src/store/modals';
import { type ModalProps, type ModalType } from 'src/store/modals/types';

type ModalPropsGetter<T extends ModalType> = (
  onClose: () => void,
  setState: (componentProps: Partial<ModalProps<T>>
  ) => void) => ModalProps<T>;

type ReduxModalState<T extends ModalType> = [
  (props?: ModalProps<T>) => void,
  () => void,
  (props: Partial<ModalProps<T>>) => void
];

export function useReduxModalWithDispatch<T extends ModalType>(
  dispatch: Dispatch<any>,
  type: T,
  getDialogProps?: ModalPropsGetter<T>
): ReduxModalState<T> {
  const closeModal = () => {
    dispatch(closeModalAction({ type }));
  };

  const updateModalState = (componentProps: Partial<ModalProps<T>>) => {
    dispatch(updateModalAction({ type, componentProps }));
  };

  const openModal = (componentProps?: ModalProps<T>) => {
    if (getDialogProps) {
      dispatch(
        openModalAction({
          type,
          componentProps: getDialogProps(closeModal, updateModalState)
        })
      );

      return;
    }

    dispatch(openModalAction({ type, componentProps }));
  };

  return [openModal, closeModal, updateModalState];
}

export function useModal<T extends ModalType>(
  type: T,
  getDialogProps?: ModalPropsGetter<T>
): ReduxModalState<T> {
  const dispatch = useDispatch();

  return useReduxModalWithDispatch(dispatch, type, getDialogProps);
}
