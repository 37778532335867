import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import React from 'react';
import { LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';
import { type ILeaveApplicationEntity } from 'src/types/leaveApplications/types';
import styled from 'styled-components';
import { ReactComponent as SignIcon } from 'src/assets/images/sign.svg';

const CustomButton = styled(Button)`
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0px 8px;
  color: ${(props) => props.theme.colors.primary};
`;

interface IProps {
  record: ILeaveApplicationEntity;
  pendingApplicationId: number | null;
  onEdit: (record: ILeaveApplicationEntity) => (e: React.MouseEvent<HTMLElement>) => void;
}

export const ActionColumn: React.FC<IProps> = ({ record, pendingApplicationId, onEdit }) => {
  function renderActions() {
    if (pendingApplicationId === record.vacationId) {
      return null;
    }

    if (LeaveApplicationStatus.familiarizationWithOrder === LeaveApplicationStatusId[record.statusId]) {
      return (
        <Tooltip placement="left" title="Підписати">
          <CustomButton onClick={onEdit(record)} type="text" data-testid="signLeaveButton">
            Підписати
            <SignIcon style={{ width: 16 }} />
          </CustomButton>
        </Tooltip>
      );
    }

    if (
      [LeaveApplicationStatus.project, LeaveApplicationStatus.rework].includes(
        LeaveApplicationStatusId[record.statusId]
      )
    ) {
      return (
        <Tooltip placement="left" title="">
          <CustomButton onClick={onEdit(record)} type="text" data-testid="editLeaveButton">
            Редагувати
            <EditOutlined />
          </CustomButton>
        </Tooltip>
      );
    }

    return null;
  }

  return <Space size="middle">{renderActions()}</Space>;
};
