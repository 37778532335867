import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { Typography, Button, Flex, Grid, Form } from 'antd';
import { Input, LeftOutlined } from 'src/components';
import styled from 'styled-components';

import { CODE_NUMBER_LENGTH } from 'src/constants/codeNumber';
import { clearSessionsError, clearSessionsState } from 'src/store/sessions';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LeftOutlinedStyled = styled(LeftOutlined)`
  color: ${props => props.theme.antd.colorTextSecondary};
  margin-right: 5px;
`;

const DescriptionWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin-bottom: 40px;
  text-align: ${(props) => props.isMobile ? 'center' : ''}
`;

const ButtonContainer = styled(Flex)`
  padding-top: 32px;
`;

export interface IConfirmCodeData {
  code?: string;
}

interface IProps {
  loading: boolean;
  error: any;
  onSubmit: (data: IConfirmCodeData) => void;
  removeError?: (value: string) => void;
}

const RESET_TIMEOUT = 1000 * 120;

export const ConfirmCodeForm: React.FC<IProps> = (props) => {
  const { loading, error, onSubmit, removeError = null } = props;
  const [data, setData] = useState<IConfirmCodeData>({ code: '' });
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const screen = useBreakpoint();

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(clearSessionsState());
    }, RESET_TIMEOUT);

    return () => {
      clearTimeout(id);
    }
  }, [dispatch]);

  const handleChange = useCallback(
    (value: string, name: string) => {
      dispatch(clearSessionsError());
      setIsDisabled(!(value.length >= CODE_NUMBER_LENGTH));

      if (value.length > CODE_NUMBER_LENGTH) {
        return;
      }

      setData((prev) => ({
        ...prev,
        [name]: value
      }));

      if (error && removeError) removeError(name);
    },
    [removeError, error, dispatch]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(data);
  }, [onSubmit, data]);

  const debouncedSubmit = useMemo(() => {
    return debounce(handleSubmit, 500);
  }, [handleSubmit]);

  const handleBack = useCallback(() => {
    dispatch(clearSessionsState());
  }, [dispatch]);

  return (
    <div data-testid='ConfirmCodeForm'>
      <Typography.Title level={5} style={{ fontWeight: 400 }}>
        <a onClick={handleBack}>
          <LeftOutlinedStyled />Повернутись на головну
        </a>
      </Typography.Title>
      <TitleWrapper>
        <Title level={5}>Введіть код доступу</Title>
      </TitleWrapper>
      <DescriptionWrapper isMobile={!screen.sm}>
        <Text type='secondary'>
          На Ваш номер телефону відправлено СМС-повідомлення з кодом для авторизації в інфо-боксі, введіть його, будь ласка, в полі нижче
        </Text>
      </DescriptionWrapper>
      <Text type='secondary'>Код доступу</Text>
      <Form onFinish={debouncedSubmit}> { /* trigger on Enter keypress */}
        <Input
          name='code'
          placeholder='XXXXX'
          size='large'
          type='tel'
          onChange={handleChange}
          value={data.code}
          helpText='Введіть код доступу протягом 2 хвилин'
          errors={error && [error]}
        />
        <ButtonContainer>
          <Button
            type='primary'
            size='large'
            disabled={isDisabled}
            block
            onClick={window.Cypress ? handleSubmit : debouncedSubmit}
            loading={loading}
          >
            Продовжити
          </Button>
        </ButtonContainer>
      </Form>
    </div>
  );
};
