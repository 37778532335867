import React from 'react';
import { Modal, Typography } from 'antd';
import { type ConfirmLogoutModalProps } from './ConfirmLogoutModalProps';
import { ReactComponent as ExclamationIcon } from 'src/assets/images/exclamation.svg';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 24px;
  text-align: center;
`;

export const ConfirmLogoutModal = ({ onClose, onSubmit }: ConfirmLogoutModalProps) => {
  return (
    <Modal
      data-testid="ConfirmLogoutModal"
      open
      title="Вихід з облікового запису"
      onOk={onSubmit}
      onCancel={onClose}
      cancelText="Ні, продовжити роботу"
      okText="Так, вийти"
    >
      <Content>
        <ExclamationIcon />
        <Typography.Text style={{ fontSize: '16px' }}>
          Ви впевнені, що хочете вийти?
        </Typography.Text>
      </Content>
    </Modal>
  );
};
