import React, { useCallback } from 'react';

import { Button, Flex } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import { LeaveApplicationStatus } from 'src/constants/status';
import { type RangeValue } from 'src/types/common';
import { FilterRangePicker } from '../LeaveApplicationTable/components/FilterRangePicker';
import { type Filters } from 'src/types/leaveApplications/types';
import { FilterButton } from '../LeaveApplicationTable/components/FilterButton';
import { FilterSelect } from '../LeaveApplicationTable/components/FilterSelect';
import { dateToTimeZone, formatDateWithoutTimezone } from 'src/utils/date';
import styled from 'styled-components';

const ResetFiltersButton = styled(Button)`
  height: 38px;
`;

const SELECT_FILTER_OPTIONS = [
  LeaveApplicationStatus.familiarizationWithOrder,
  LeaveApplicationStatus.managerApprovalWaiting,
  LeaveApplicationStatus.orderSentToUser,
  LeaveApplicationStatus.orderSignedAndInformed,
  LeaveApplicationStatus.rejected
];

interface Props {
  onFilterChange: (params: Filters) => void;
  onResetFilters: () => void;
  filters: Filters;
  itemsCount: number;
}

export const LeaveApplicationFilters: React.FC<Props> = ({ onFilterChange, onResetFilters, filters, itemsCount }) => {
  const handleChangeRange = useCallback((name: string, date: Moment | null) => {
    if (name === 'dateFrom') {
      onFilterChange({
        dateFrom: date && formatDateWithoutTimezone(dateToTimeZone(date.startOf('day')))
      });
    }

    if (name === 'dateTo') {
      onFilterChange({
        dateTo: date && formatDateWithoutTimezone(dateToTimeZone(date.endOf('day')))
      });
    }
  }, [onFilterChange]);

  const handleChangeStatus = (status: LeaveApplicationStatus) => {
    if (filters.status === status) {
      onFilterChange({ status: null });
    } else {
      onFilterChange({ status });
    }
  };

  const rangeValue = [
    filters.dateFrom && moment(filters.dateFrom),
    filters.dateTo && moment(filters.dateTo)
  ] as RangeValue<Moment | null>;

  return (
    <Flex gap={8} style={{ margin: '8px 0' }} wrap='wrap'>
        <FilterRangePicker value={rangeValue} onChange={handleChangeRange} />
      {/* <div style={{ display: 'none' }}> */}
        <FilterButton
          status={LeaveApplicationStatus.project}
          onChange={handleChangeStatus}
          statusFilter={filters.status}
          itemsNumber={itemsCount}
        />
        <FilterButton
          status={LeaveApplicationStatus.rework}
          onChange={handleChangeStatus}
          statusFilter={filters.status}
          itemsNumber={itemsCount}
        />
        <FilterButton
          status={LeaveApplicationStatus.archive}
          onChange={handleChangeStatus}
          statusFilter={filters.status}
          itemsNumber={itemsCount}
        />
        <FilterSelect
          statusFilter={filters.status}
          options={SELECT_FILTER_OPTIONS}
          onChange={handleChangeStatus}
        />
      {/* </div> */}
      <ResetFiltersButton type='text' onClick={onResetFilters}>
        Очистити фільтр
      </ResetFiltersButton>
    </Flex>
  );
};
