import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Grid } from 'antd';
import { SignaturePad } from 'src/components';
import { type SignLeaveApplicationModalProps } from './SignLeaveApplicationModalProps';
import { SignLeaveApplicationTable } from '../../../leaveApplications/components/SignLeaveApplicationTable';
import { useSignatureValidation } from 'src/features/leaveApplication/hooks/useSignatureValidation';

const { useBreakpoint } = Grid;

export const SignLeaveApplicationModal = ({
  title,
  data,
  isLoading,
  onClose,
  onSubmit
}: SignLeaveApplicationModalProps) => {
  const { signaturePadRef, signaturePad, isSignatureValid, setIsSignatureValid } = useSignatureValidation();
  const [isDisabled, setDisabled] = useState(false);
  const screen = useBreakpoint();
  const isMobile = !!screen.xs;

  useEffect(() => {
    if (isLoading) {
      setDisabled(false);
    }
  }, [isLoading]);

  const handleSubmitSignature = useCallback(() => {
    setDisabled(true);

    const signature = signaturePad?.instance.toDataURL();

    if (signature) { onSubmit(signature); }
  }, [onSubmit, signaturePad]);

  const handleClearSignature = () => {
    signaturePad?.instance.clear();
    setIsSignatureValid(false);
  };

  return (
    <Modal
      open
      data-testid="SignLeaveApplicationModal"
      title={title}
      okText="Підписати"
      cancelText="Скасувати"
      onOk={handleSubmitSignature}
      okButtonProps={{ loading: isLoading, disabled: !isSignatureValid || isDisabled }}
      onCancel={onClose}
      width={isMobile ? '100%' : '80%'}
      centered
      cancelButtonProps={{ disabled: isLoading }}
    >
      <SignLeaveApplicationTable data={data} />
      <SignaturePad ref={signaturePadRef} onClear={handleClearSignature} />
    </Modal>
  );
};
