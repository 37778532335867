export const ACCEPTED_FILE_EXTENSIONS = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODT: 'application/vnd.oasis.opendocument.text'
}

export const ALLOWED_PREVIEW_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];

export const MIME_TYPES_BY_BASE_64 = {
  JVBERi0: ACCEPTED_FILE_EXTENSIONS.PDF,
  iVBORw0KGgo: ACCEPTED_FILE_EXTENSIONS.PNG,
  '/9j/4': ACCEPTED_FILE_EXTENSIONS.JPEG,
  '0xD0CF11E0A1B11AE1': ACCEPTED_FILE_EXTENSIONS.DOC,
  PK: ACCEPTED_FILE_EXTENSIONS.DOCX,
  'UEsDBBQABgAIAAAAIQDpAAAAwQAAAMABAAEAAEABwAcmVsc3BvbnNlLmRvY3iTAQADAQBEAADbwQAAAAA=': ACCEPTED_FILE_EXTENSIONS.ODT
};
