/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import { notification } from 'antd';

export const showNotification = ({
  type = 'success',
  message,
  description
}: {
  type?: string;
  message?: any;
  description?: any;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  notification[type]({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(message ? { message } : type),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(description ? { description } : type),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    duration: 7
  });
}
