import ApiClient from './ApiClient';
import SessionsAPI from './Sessions';
import ActionsAPI from './Actions';
import LeaveApplicationsAPI from './LeaveApplications';
import UsersAPI from './Users';
import LeaveApplicationTypesAPI from './LeaveApplicationTypes';
import DocumentsAPI from './Documents';
import LeaveApplicationHistory from './LeaveApplicationHistory';

const makeApiList = ({ apiPrefix }: { apiPrefix?: string } = {}) => {
  if (!apiPrefix) {
    throw new Error('[apiPrefix] required');
  }

  const api = new ApiClient({ prefix: apiPrefix });

  return {
    apiClient: api,
    sessions: new SessionsAPI({ apiClient: api }),
    actions: new ActionsAPI({ apiClient: api }),
    leaveApplications: new LeaveApplicationsAPI({ apiClient: api }),
    users: new UsersAPI({ apiClient: api }),
    leaveApplicationTypes: new LeaveApplicationTypesAPI({ apiClient: api }),
    leaveApplicationHistory: new LeaveApplicationHistory({ apiClient: api }),
    documents: new DocumentsAPI({ apiClient: api })
  };
};

export default makeApiList;
