import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import api from 'src/app/apiSingleton';

import { ROUTES } from 'src/constants/routes';
import { parseServerError } from 'src/constants/error';

import { SessionView, type ILoginPayload } from './types';
import {
  clearSessionsState,
  setSessionErrors,
  setSessionView,
  setUserBlockedTimestampErrors,
  setUserInfo,
  setUserProfile
} from './sessions.slice';
import { type IRedirect } from '../modals/types';
import { type RootState } from 'src/store/rootStore';
import { sessionStorage } from 'src/utils/storage';
import { errorsCodes } from 'src/constants/errors/codes';
import { navigateTo } from 'src/utils/navigation';
import { profileSelector } from './sessions.selectors';
import { clearModalState } from '../modals';
import { cleanUpCurrentLeaveApplication, cleanUpLeaveApplicationState } from '../leaveApplications';
import { handleException } from '../utils';
import { GAEvents } from 'src/constants/gaEvents';
import type { IProfile } from 'src/types/user/types';

export const requestCode = createAsyncThunk('sessions/request-code', async (data: ILoginPayload, thunkApi) => {
  try {
    const { credentials } = data;

    await api.sessions.requestCode(credentials);

    thunkApi.dispatch(setSessionView(SessionView.confirmCode));
    thunkApi.dispatch(setUserInfo({ userPhone: credentials.phone }));
  } catch (err: any) {
    const { message } = parseServerError(err);

    if (err.code === errorsCodes.USER_BLOCKED) {
      thunkApi.dispatch(setSessionErrors(message));
      thunkApi.dispatch(setUserBlockedTimestampErrors(err.data.timestamp));
      thunkApi.dispatch(setSessionView(SessionView.timer));

      return;
    }

    if (err.code === errorsCodes.USER_FORBIDDEN) {
      thunkApi.dispatch(setSessionErrors(message));

      return;
    }

    if (err.code === errorsCodes.SERVER_ERROR) {
      handleException(err, thunkApi.dispatch);

      return;
    }

    thunkApi.dispatch(setSessionErrors(message));

    throw err;
  }
});

export const confirmCode = createAsyncThunk('sessions/confirm-code', async (data: ILoginPayload, thunkApi) => {
  try {
    const { credentials } = data;
    const state = thunkApi.getState() as RootState;

    const { data: { jwtToken } } = await api.sessions.confirmCode({
      code: credentials.code,
      phone: state.sessions.userPhone
    });

    const userProfile: IProfile = jwtDecode(jwtToken);

    sessionStorage.save('sessionToken', jwtToken);
    api.apiClient.setToken(jwtToken);
    thunkApi.dispatch(setUserProfile(userProfile));

    (window as any).dataLayer.push({
      event: GAEvents.Login,
      pib: userProfile.pib,
      company: userProfile.company,
      department: userProfile.department,
      position: userProfile.position
    });

    navigateTo(ROUTES.ROOT);
  } catch (err: any) {
    const { message } = parseServerError(err);

    if (err.code === errorsCodes.SERVER_ERROR) {
      handleException(err, thunkApi.dispatch);

      return;
    }

    thunkApi.dispatch(setSessionErrors(message));

    if (err.code === errorsCodes.USER_BLOCKED) {
      thunkApi.dispatch(setSessionView(SessionView.timer));
    }
  }
});

export const logout = createAsyncThunk('sessions/logout', async (data: IRedirect, thunkApi) => {
  try {
    const { redirectTo } = data;

    sessionStorage.remove('sessionToken');
    redirectTo(ROUTES.LOGIN, { replace: true });

    thunkApi.dispatch(clearSessionsState());
    thunkApi.dispatch(clearModalState());
    thunkApi.dispatch(cleanUpCurrentLeaveApplication());
    thunkApi.dispatch(cleanUpLeaveApplicationState());
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const getPartTimeJobProfiles = createAsyncThunk(
  'sessions/partTimeJobProfiles',
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const profile = profileSelector(state);

      const partTimeJobProfiles = await api.users.get(profile?.principalName as string);

      return partTimeJobProfiles.data
    } catch (err: any) {
      handleException(err, thunkApi.dispatch);

      throw err;
    }
  }
);
