import React from 'react';
import { Modal, Typography } from 'antd';
import { type DeleteLeaveApplicationModalProps } from './DeleteLeaveApplicationModalProps';
import { ReactComponent as ExclamationIcon } from 'src/assets/images/exclamation.svg';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 48px 24px;
  text-align: center;
`;

export const DeleteLeaveApplicationModal = ({
  onClose,
  onSubmit
}: DeleteLeaveApplicationModalProps) => {
  return (
    <Modal
      data-testid="DeleteLeaveApplicationModal"
      open
      title="Скасувати створення заявки"
      onOk={onSubmit}
      onCancel={onClose}
      cancelText="Ні, продовжити створення"
      okType="danger"
      okText="Так, скасувати"
      okButtonProps={{ type: 'primary' }}
      width={800}
    >
      <Content>
        <ExclamationIcon />
        <Typography.Text style={{ fontSize: '16px' }}>
          Ви впевнені, що хочете скасувати створення заявки? Усі введені дані будуть втрачені, і
          заявка не буде збережена
        </Typography.Text>
      </Content>
    </Modal>
  );
};
