import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.warningLight};
  border: 1px solid ${(props) => props.theme.colors.warning};
  padding: 8px;
  text-align: center;
`;

export const ApplicationsWarning = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <Typography.Text style={{ fontWeight: 500 }}>
        {children}
      </Typography.Text>
    </Container>
  );
};
