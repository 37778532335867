import { Divider, Steps } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  InfoForm,
  LeaveApplication,
  PartTimeJobForm,
  UploadDocumentsForm,
  useSignApplication,
  useStepperForm,
  useValidateApplicationStepper
} from 'src/features/leaveApplication';
import { STEP_NUMBER_BY_NAME } from 'src/features/leaveApplication/hooks/useStepperForm';
import { partTimeJobsValidator, prepareDocumentsValidator, prepareInfoValidator } from 'src/features/leaveApplication/utils/stepValidators';
import { useAppDispatch } from 'src/hooks';
import {
  cleanUpCurrentLeaveApplication,
  submitLeaveApplication,
  existentPartTimeJobsLeaveApplicationsSelector,
  currentLeaveApplicationPartTimeJobsIsLoadingSelector,
  currentLeaveApplicationSelector,
  getLeaveApplicationTypesList,
  leaveApplicationTypesIsLoadingSelector,
  leaveApplicationTypesSelector,
  partTimeJobsItemsSelector,
  getPartTimeJobs,
  validateLeaveApplicationDates
} from 'src/store/leaveApplications';
import {
  getPartTimeJobProfiles,
  partTimeJobProfilesSelector,
  profileSelector
} from 'src/store/sessions';
import {
  STEPPER_MODE,
  type ILeaveApplicationFormData
} from 'src/types/leaveApplications/types';
import { type IProfile } from 'src/types/user/types';
import styled from 'styled-components';

const Section = styled.section`
  padding: 0 28px;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${(props) => props.theme.antd.colorWhite};
  }
`;

const { Container, Content, Footer } = LeaveApplication;

export const CreateLeave: React.FC = () => {
  const dispatch = useAppDispatch();

  const profile = useSelector(profileSelector) as IProfile;
  const partTimeJobProfiles = useSelector(partTimeJobProfilesSelector);
  const currentLeaveApplication = useSelector(currentLeaveApplicationSelector);
  const partTimeJobsIsLoading = useSelector(currentLeaveApplicationPartTimeJobsIsLoadingSelector);
  const existentPartTimeJobsLeaveApplications = useSelector(existentPartTimeJobsLeaveApplicationsSelector);
  const leaveApplicationTypes = useSelector(leaveApplicationTypesSelector);
  const leaveApplicationTypesIsLoading = useSelector(leaveApplicationTypesIsLoadingSelector);
  const partTimeJobsItems = useSelector(partTimeJobsItemsSelector);

  const { formData, errors, resetStepErrors, changeStepErrors, changeFormData } =
    useStepperForm({
      profile,
      mode: STEPPER_MODE.CREATE,
      partTimeJobs: partTimeJobsItems,
      leaveApplication: currentLeaveApplication.data,
      leaveApplicationTypes
    });

  const isPartTimeJobSelected = useMemo(() => {
    const [, ...onlyPartTimeJobProfiles] = partTimeJobProfiles;

    return onlyPartTimeJobProfiles?.some(
      (profile) => profile.positionIdentifier === formData.info.positionIdentifier
    );
  }, [partTimeJobProfiles, formData.info.positionIdentifier]);

  const showPartTimeJobsStep = partTimeJobProfiles.length > 1 && !isPartTimeJobSelected;

  useSignApplication({
    formData,
    currentLeaveApplication
  });

  const validateApplicationDates = async () => {
    await dispatch(validateLeaveApplicationDates(formData)).unwrap();
  }

  const handleSubmit = (data: ILeaveApplicationFormData) => {
    dispatch(submitLeaveApplication(data));
  }
  const { currentStep, currentStepErrors, isLastStep, handleCancel, handleNext, handlePrev } =
    useValidateApplicationStepper({
      formData,
      errors,
      showPartTimeJobsStep,
      mode: STEPPER_MODE.CREATE,
      resetStepErrors,
      onSubmit: handleSubmit,
      stepValidators: [
        prepareInfoValidator(formData, false, changeStepErrors, validateApplicationDates),
        prepareDocumentsValidator(formData, changeStepErrors),
        partTimeJobsValidator
      ]
    });

  useEffect(() => {
    dispatch(getLeaveApplicationTypesList());
    dispatch(getPartTimeJobProfiles());

    return () => {
      dispatch(cleanUpCurrentLeaveApplication());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentStep === STEP_NUMBER_BY_NAME.partTimeJobs) {
      dispatch(getPartTimeJobs(formData));
    }
  }, [currentStep]);

  const isLoading =
    currentLeaveApplication.isLoading ||
    partTimeJobsIsLoading ||
    leaveApplicationTypesIsLoading;

  const getSteps = () => {
    const defaultSteps = [
      {
        title: 'Інформація про відпустку',
        content: (
          <InfoForm
            data={formData.info}
            errors={currentStepErrors}
            partTimeJobProfiles={partTimeJobProfiles}
            leaveApplicationTypes={leaveApplicationTypes}
            leaveApplicationTypesIsLoading={leaveApplicationTypesIsLoading}
            onChange={changeFormData('info')}
            disabled={isLoading}
          />
        )
      },
      {
        title: 'Документи',
        content: (
          <UploadDocumentsForm
            documents={formData.documents}
            comment={formData.info.comment}
            errors={currentStepErrors}
            onChange={changeFormData('documents')}
            onChangeComment={changeFormData('info')}
          />
        )
      }
    ];

    if (showPartTimeJobsStep) {
      defaultSteps.push({
        title: 'Інформація про сумісництво',
        content: (
          <PartTimeJobForm
            data={formData}
            existentPartTimeJobsLeaveApplications={existentPartTimeJobsLeaveApplications}
            onChange={changeFormData('partTimeJobs')}
            isLoading={isLoading}
            partTimeJobsIsLoading={partTimeJobsIsLoading}
          />
        )
      });
    }

    return defaultSteps;
  }

  const steps = getSteps()
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const StepContent = steps[currentStep].content;

  return (
    <Container>
      <>
          <Content>
            <Section style={{ margin: '14px 0 28px 0px' }}>
              <StyledSteps current={currentStep} items={items} />
            </Section>
            {StepContent}
          </Content>
          <Divider />
          <Footer
            okText={isLastStep ? 'Підписати' : 'Продовжити'}
            onOk={handleNext}
            prevText={currentStep === 0 ? 'Скасувати' : 'Назад'}
            onPrev={handlePrev}
            cancelText="Скасувати"
            onCancel={currentStep !== 0 ? handleCancel : undefined}
            disabled={isLoading || currentLeaveApplication.isValidationLoading}
          />
        </>
    </Container>
  );
};
